<div class="btn-group" *ngIf="entity.is_open && entity.is_active">
  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [appEntityAlertDisabler]="entity">
    Actions rapides <span class="caret"></span>
  </button>
  <ul class="dropdown-menu">
    <li class="dropdown-header"
        *ngIf="hasPermissions('EvaBackend:EntityCanDisable', 'EvaBackend:EntityCanClose')">
        Entité
    </li>
    <li *ngIf="hasPermissions('EvaBackend:EntityCanDisable')">
      <a (click)="disableSiteConfirm()" >Désactiver le site</a>
    </li>
    <li *ngIf="hasPermissions('EvaBackend:EntityCanClose')">
      <a (click)="closeSiteConfirm()" >Fermer le site</a>
    </li>
    <li *ngIf="hasPermissions('EvaBackend:CanCreateSalesforceAccount')">
      <a (click)="enableSfSyncConfirm()" >Synchroniser vers Salesforce</a>
    </li>
    <li role="separator" class="divider" *ngIf="hasPermissions('EvaBackend:EntityCanDisable', 'EvaBackend:EntityCanClose')"></li>

    <li class="dropdown-header">Planifier intervention</li>
    <li><a (click)="newWoiIntervention()" >Intervention sur site</a></li>
    <li role="separator" class="divider"></li>
    <li class="dropdown-header">Tâches</li>
    <li><a (click)="newWoi()" >Nouvelle tâche</a></li>
    <li>
      <a routerLink="/work-order-items/list" [queryParams]="{work_order__entity__code: entity.code}">
        <i class="fa fa-external-link-alt" aria-hidden="true"></i> Tâches associées
      </a>
    </li>

    <li role="separator" class="divider" *ngIf="hasPermissions('ShivaBackend:CanShipment')"></li>
    <li class="dropdown-header" *ngIf="hasPermissions('ShivaBackend:CanShipment')">Expéditions</li>
    <li><a (click)="newWoiShipment()" *ngIf="hasPermissions('ShivaBackend:CanShipment')">Nouvelle expédition</a></li>
    <li><a (click)="newLogisticsRequest()"  *ngIf="hasPermissions('ShivaBackend:LogisticsRequestCanCreate')">Nouvelle demande logistique</a></li>

    <li *ngIf="hasPermissions('Wira:EqpCanAssign')" role="separator" class="divider"></li>
    <li *ngIf="hasPermissions('Wira:EqpCanAssign')" class="dropdown-header">Stock</li>
    <li *ngIf="hasPermissions('Wira:EqpCanAssign')"><a (click)="assignStock()" >Affecter un équipement</a></li>
    <li *ngIf="hasPermissions('Wira:EqpCanAssign')"><a (click)="assignBulkStock()" >Affecter des équipements</a></li>
    <li *ngIf="hasPermissions('Wira:EqpCanAssign')"><a (click)="showHistoryStock()" >Historique</a></li>

    <li role="separator" class="divider" *ngIf="hasPermissions('EvaBackend:QuoteCanCreate')"></li>
    <li class="dropdown-header" *ngIf="hasPermissions('EvaBackend:QuoteCanCreate')">Commerce</li>
    <li *ngIf="hasPermissions('EvaBackend:QuoteCanCreate')">
      <a routerLink="/quotes/detail" [queryParams]="{quote_entity_code: entity.code}">Nouveau devis</a>
    </li>

    <li role="separator" class="divider" *ngIf="hasPermissions('EvaBackend:SalesUser')"></li>
    <li class="dropdown-header" *ngIf="hasPermissions('EvaBackend:SalesUser')">Finance</li>
    <li *ngIf="hasPermissions('EvaBackend:SalesUser')">
      <a (click)="downloadMandate()" >Éditer un mandat de prélévement</a>
    </li>

    <li role="separator" class="divider" *ngIf="hasPermissions('Provitool:NetworkAdmin')"></li>
    <li class="dropdown-header"
        *ngIf="hasPermissions('Provitool:NetworkAdmin')">
        Réseaux
    </li>
    <li *ngIf="hasPermissions('Argos:MaintenanceWindowCanCreate')">
      <a (click)="addMaintenance()" >Ajouter une maintenance</a>
    </li>

  </ul>
</div>
<button type="button" class="btn btn-default" (click)="enableSiteConfirm()"
        *ngIf="!entity.is_active && hasPermissions('EvaBackend:EntityCanDisable')">
  Réactiver le site
</button>
<button type="button" class="btn btn-default" (click)="openSiteConfirm()"
        *ngIf="!entity.is_open && entity.is_active && hasPermissions('EvaBackend:EntityCanClose')">
  Réouvrir le site
</button>
