<app-wcm-table [api]="apiShiva.quotes" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="quotes-list-refresh" urlUpdateSignal="quotes-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="quotes-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle targetRouterLink="/quotes/list" text="Devis" [targetQueryParams]="{ordering: '-created_at'}"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()" *ngIf="!localDisabledButtons.create && hasPermissions('EvaBackend:QuoteCanCreate')">
      <i class="fas fa-plus"></i> Nouveau devis
    </button>
  </ng-template>

  <app-wcm-col field="number_or_code" header="Code" class="text-center" [preventSort]="true" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.number || item.code}}" link="/quotes/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_at" header="Créé le" type="dateRange" class="text-center">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-date-range-field [key]="col.field" [filters]="filters" [addTime]="true"
                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
      </app-date-range-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date" header="Prêt le" type="dateRange" class="text-center"></app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center"></app-wcm-col>

  <app-wcm-col field="salesperson" header="Commercial" type="user" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__name" header="Client">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity.name}}" link="/entities/detail/{{item.entity.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="customer_ref" header="Réf client (Devis)"></app-wcm-col>

  <app-wcm-col field="entity__parent__name" header="Parent">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.name}}"
                link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <!-- note that the field name "sf_opportunity_or_name" is specified in Salesforce to deep-link to antoine
    filtered by this column, and hence should not be changed without changing Salesforce as well.
  -->
  <app-wcm-col field="sf_opportunity_or_name" header="Opportunité" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="{{salesforceBaseUrl}}/{{item?.sf_opportunity_id}}" target="_blank"
         *ngIf="item.sf_opportunity_id; else noSFId">
        {{item.sf_opportunity_name}}
      </a>
      <ng-template #noSFId>
        {{item.sf_opportunity_name}}
      </ng-template>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="project__name" header="Projet" type="nullableText"
               [additionalArgs]="{isNullKey: 'project__isnull'}" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="setup_total_untaxed" header="FA Total HT" type="none"
               class="text-right" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.setup_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="recurring_total_untaxed" header="FR Total HT" type="none"
               class="text-right" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.recurring_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" type="multipleStates" [additionalArgs]="{workflows: ['quotes']}"
               header="Statut" class="text-center" width="120">
  </app-wcm-col>

</app-wcm-table>
