import { EQP_LOCATIONS } from '@core/constants';
import {
  IMerakiMSNetworkDevice,
  IMerakiMXNetworkDevice,
  IMerakiNetworkDevice,
  ITinyNetworkDevice
} from '@core/interfaces/network-devices-items';
import { IEntity } from '@core/interfaces/entity-items';
import { IEquipmentLocation } from '@core/interfaces/logistics-sites';
import { IEquipmentModel } from '@core/interfaces/equipment-models-items';

export enum ScanStatusEnum {
  Ignored = 'ignored',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export type TScanStatus = ScanStatusEnum.Error | ScanStatusEnum.Ignored | ScanStatusEnum.Success | ScanStatusEnum.Warning | undefined;

export interface IMiniEquipment {
  id: number;
  seq?: string;
  code?: string;
  model_need_mac_address?: boolean;
  mac_address?: string;
  serial_number?: string;
}

export type TEquipmentLocation = keyof typeof EQP_LOCATIONS;

export interface ITinyEquipment extends IMiniEquipment {
  serial_number?: string;
  internal_serial_number?: string;
  project_number?: string;
  equipment_location?: IEquipmentLocation;
  model?: IEquipmentModel;
  reserved_for?: {
    code?: string,
    name?: string,
    parent?: { code?: string },
  };
  entity?: {
    code?: string,
    name?: string,
  };
  location?: TEquipmentLocation;
  isInvalid?: boolean;
}

export interface IEquipment extends ITinyEquipment {
  // TODO-Type as Entity
  entity?: {
    id?: any,
    code?: any,
    name?: any,
    customer_ref?: any,
    location?: any,
    parent?: any,
  };
  location?: any;
  immobilization_number?: any;
  owner?: string;
  // TODO-Type as Entity
  reserved_for?: {
    id?: any,
    code?: any,
    name?: any,
    parent?: any,
  };
  // TODO-Type as ITags[]
  tags?: any[];
  last_stock_activity?: string;
  mac_address?: any;
  provider_order_number?: string;
  price_untaxed?: number;
  license_price?: number;
  // TODO-Type as IAccountingEquipment
  accounting_equipment?: any;
  // TODO-Type as IProviderOrder
  provider_order?: any;
  has_accounting_equipment?: boolean;
  created_at?: string;
  zone?: string;
}

export interface IEquipmentRecord {
  id: number;
  created_at: string;
  equipment: ITinyEquipment;
  entity: IEntity;
  author: string;
  status: string;
  comment?: string;

  text?: string;
}

export interface IEquipmentSlotData {
  scannedEquipment: IMetadataScannedEquipment;
  equipment: IEquipment | undefined;
}

export interface IScannedMerakNetworkDevice {
  id: string;
  code: string;
  name: string;
  spare_name?: string;
}

export interface IMetadataScannedEquipment {
  code: string;
  is_scanned: boolean;
  scan_status?: TScanStatus;
  id?: number;
  mac_address?: string;
  serial_number?: string;
  model_need_mac_address?: boolean;
  internal_serial_number?: string;
  error_messages?: string[];
  equipment?: IEquipment;
  owner?: string;
  network_device?: IScannedMerakNetworkDevice;
  model?: {
    id?: number,
    name?: string,
  };
  location?: TEquipmentLocation | undefined;
  reserved_for?: ITinyEquipment['reserved_for'];
  is_secondary_eqp?: boolean;
}

export interface IRequiredEquipment {
  code?: string;
  serial_number?: string;
  internal_serial_number?: string;
}
