<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>

      <a [href]="zabbixBaseUrl.format(detail.zabbix_id)"
          class="btn btn-transparent" style="margin-right: 5px;"
          target="_blank" role="button" *ngIf="detail.zabbix_id">
        <i class="fa fa-external-link-alt" aria-hidden="true"></i> Zabbix
      </a>

      <a [href]="zabbixBaseUrl.format(detail.minor_zabbix_id)"
          class="btn btn-transparent" style="margin-right: 5px;"
          target="_blank" role="button" *ngIf="detail.minor_zabbix_id">
        <i class="fa fa-external-link-alt" aria-hidden="true"></i> Zabbix Secondary
      </a>

      <span class="label label-danger button-like-label"
            style="margin-right: 5px;"
            *ngIf="detail.is_active === false && mode === 'normal'">
        Équipement supprimé
      </span>

      <ng-container *ngIf="mode === 'normal'">
        <ng-container *ngIf="hasPermissions('Argos:MaintenanceWindowCanCreate')">
          <button type="button" class="btn btn-default"
                [disabled]="detail.state?.name !== 'active'" (click)="addMaintenance()">
            Ajouter une maintenance
          </button>
        </ng-container>
        <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">

          <div class="btn-group">
            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
                    [disabled]="!detail.config_template" *ngIf="detail.is_active && !merakiTypes.includes(detail.type) && detail.type !== 'firewall'"
                    title="{{detail.config_template ? '' : 'Un modèle de configuration est nécessaire'}}">
              Provisionnement <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a  *ngIf="hasPermissions('Antoine:NetworkDeviceCanProvi')"
                    (click)="openProviModal('provisioning')" role="button">Provisionner un équipement</a>
              </li>
              <li>
                <a (click)="openProviModal('test_config')" role="button">Tester la configuration</a>
              </li>
              <li *ngIf="detail.last_config_attach_id">
                <a (click)="openProviHistoriesAttachmentsModal(pk, detail.last_config_attach_id, 'config')" role="button">
                  <i class="fas fa-eye" aria-hidden="true"></i> Dernière configuration connue
                </a>
              </li>
              <li *ngIf="detail.last_dump_attach_id">
                <a (click)="openProviHistoriesAttachmentsModal(pk, detail.last_dump_attach_id, 'dump')" role="button">
                  <i class="fas fa-eye" aria-hidden="true"></i> Dernier dump connu
                </a>
              </li>
            </ul>
          </div>

          <button type="button" class="btn btn-danger" (click)="confirmDelete()"
                  [disabled]="loading" *ngIf="detail.is_active">
            Supprimer
          </button>

          <button type="button" class="btn btn-default" *ngIf="detail.is_active === true" (click)="edit()">
            Modifier
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin') && mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.code"
                [disabled]="f.invalid || loading || detail.invalidNumber || !detail.entity">
                Créer
        </button>

        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code"
                [disabled]="f.invalid || loading || detail.invalidNumber || !detail.entity || !gatewayConsistent">
          Enregistrer
        </button>

        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
          Annuler
        </button>
      </ng-container>
    </ng-template>
  </app-detail-header>

  <div class="alert alert-danger" *ngIf="detail?.id && !detail.config_template && !merakiTypes.includes(detail.type) && detail.type !== 'firewall'">
    Attention, pour provisionner un équipement, un modèle de configuration est nécessaire.
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="!detail.code && relatedND && detail.type === 'router' && detail.operator_line">
    <p *ngIf="relatedND.length === 1; else elseBlock">
      Attention l’{{ detail.operator_line.code }} est déjà affecté à l'équipement réseau {{ relatedND }}.
    </p>
    <ng-template #elseBlock>Attention l’{{ detail.operator_line.code }} est déjà affecté aux équipements réseau de cette liste : [{{ relatedND }}].</ng-template>
  </div>
  <div class="alert alert-info" *ngIf="!canSynchroZabbix && detail.type === 'firewall'">
    Attention, pour activer la synchro Zabbix les champs de séléction d'équipement "FW1/FW2" ainsi que les labels de monitoring doivent être renseignés.
  </div>
  <div class="alert alert-info" *ngIf="!canSynchroZabbix && detail.type === 'l2switch'">
    Attention, pour activer la synchro Zabbix la séléction d'équipement doit être renseigné.
  </div>

  <app-argos-event-mutes-alert [networkDevice]="detail"></app-argos-event-mutes-alert>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site <i class="required" aria-hidden="true">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field name="entity" [disabled]="true" [(ngModel)]="detail.entity">
        </app-entity-field>
      </div>
      <label class="col-sm-2 control-label">
        Créée le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.created_at | amDateFormat:'L LT'}}
        </p>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.id">
      <label class="col-sm-2 control-label">
        Statut
      </label>
      <div class="col-sm-4">
        <app-state-field model="network-device" [pk]="detail.id" [state]="detail.state"
                         [disabled]="mode !== 'normal' || loading" (transitionPlayed)="onStateUpdate()">
        </app-state-field>
      </div>
      <label class="col-sm-2 control-label">
        Créée par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="detail.created_by"></app-wcm-user>
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type <i class="required" aria-hidden="true">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" name="type"
                [disabled]="mode === 'normal' || detail.id || loading" [(ngModel)]="detail.type"
                [required]="true" (ngModelChange)="onChangeType()">
          <option *ngFor="let opt of typeOptions | keyvalue" [value]="opt.key">{{opt.value}}</option>
        </select>
      </div>

      <div *ngIf="!merakiTypes.includes(detail.type) && detail.type !== 'firewall'">
        <label class="col-sm-2 control-label">
          Modèle de conf.
        </label>
        <div class="col-sm-4">
          <app-config-template-field name="config_template" [disabled]="mode === 'normal'"
                                            [filters]="{parent_or_children__code: detail.entity?.code, type: detail.type}"
                                            [(ngModel)]="detail.config_template">
          </app-config-template-field>
        </div>
      </div>

      <div *ngIf="merakiTypes.includes(detail.type)">
        <label class="col-sm-2 control-label">
          Nom <i class="required" aria-hidden="true" *ngIf="isNameEditable">*</i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req" [required]="true" [disabled]="mode === 'normal' || !isNameEditable"
                 type="text" [(ngModel)]="detail.name" name="name"/>
        </div>
      </div>

      <div *ngIf="detail.type === 'firewall'">
        <label class="col-sm-2 control-label" for="cluster_mode">
          Cluster
        </label>
        <div class="col-sm-4">
          <app-form-select-field name="cluster_mode"
                           [(ngModel)]="detail.cluster_mode"
                           [options]="firewallClusterOptions"
                           [disabled]="mode === 'normal'"
                           (ngModelChange)="onChangeFirewallClusterNatCb()">
          </app-form-select-field>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'router' && detail.entity?.vrfs_affinities?.id === 122">
      <label class="col-sm-3 control-label" for="defaultCbx">
        Routeur internet classique <i data-toggle="tooltip" title="Configuration LAN / WAN automatique" class="fas fa-question-circle"></i>
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.is_classic_router" (ngModelChange)="updateConf()" [binary]="true" inputId="defaultCbx"
                      [disabled]="mode === 'normal'" name="is_classic_router">
        </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="merakiTypes.includes(detail.type)">
      <label class="col-sm-2 control-label">Equipement</label>
      <div class="col-sm-4">
        <app-equipment-field name="equipment"
                             [(ngModel)]="detail.equipment"
                             [filters]="{model__type_label: getModelTypeLabel(detail.type), model__category__label: 'meraki'}"
                             [disabled]="mode === 'normal'">
        </app-equipment-field>
      </div>
      <div *ngIf="detail.equipment">
        <label class="col-sm-2 control-label">SN</label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.equipment.serial_number}}</p>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="merakiTypes.includes(detail.type)">
      <div *ngIf="showMerakiInfoOrga">
        <label class="col-sm-2 control-label">Organisation Meraki</label>
        <div class="col-sm-4">
          <app-meraki-organization-field [(ngModel)]="merakiOrganization" name="merakiOrganization" [disabled]="true">
          </app-meraki-organization-field>
        </div>
      </div>

      <div *ngIf="showMerakiInfoTemplate">
        <label class="col-sm-2 control-label">Template</label>
        <div class="col-sm-4">
          <app-meraki-config-template-field [(ngModel)]="merakiTemplate" name="merakiTemplate" [disabled]="true">
          </app-meraki-config-template-field>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === MerakiTypeEnum.MS">
      <label class="col-sm-8 control-label">
        Switch Profile
      </label>
      <div class="col-sm-4">
        <app-meraki-switch-profile-field name="meraki_switch_profile"
                                         [(ngModel)]="detail.meraki_switch_profile"
                                         [disabled]="mode === 'normal'"
                                         [filters]="merakiSwitchProfileFieldFilters">
        </app-meraki-switch-profile-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Numéro
      </label>
      <div class="col-sm-4">
        <input class="form-control" name="number" type="number" min="0"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.number"
               (ngModelChange)="checkNumber()" [ngClass]="{'wcm-highlight-error': detail.invalidNumber}"
               placeholder="Laisser vide pour une attribution automatique"/>
      </div>

      <div *ngIf="detail.id && detail.type !== 'firewall'">
        <label class="col-sm-2 control-label">
          Statut
        </label>
        <div class="col-sm-4">
          <p class="form-control-static label-content" [ngSwitch]="detail.status">
            <span class="label label-success" *ngSwitchCase="'ok'" >
              UP
            </span>
            <span class="label label-warning" *ngSwitchCase="'warning'" >
              WARNING
            </span>
            <span class="label label-danger" *ngSwitchCase="'ko'" >
              DOWN
            </span>
            <span class="label label-default" *ngSwitchDefault>
              NO DATA
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'firewall'">
      <label class="col-sm-2 control-label">
        FW1
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="equipment"
                             [(ngModel)]="detail.equipment"
                             [filters]="equipmentOpt?.filters"
                             [disabled]="mode === 'normal'"
                             (ngModelChange)="updateEquipmentFilters()">
        </app-equipment-field>
      </div>

      <label class="col-sm-2 control-label">
        Constructeur
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{ detail.equipment?.model?.brand?.name }}</p>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'firewall' && detail.cluster_mode !== ClusterMode.None">
      <label class="col-sm-2 control-label">
        FW2
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="secondary_equipment"
                             [(ngModel)]="detail.secondary_equipment"
                             [filters]="secondaryEquipmentOpt?.filters"
                             [disabled]="mode === 'normal'"
                             (ngModelChange)="updateEquipmentFilters()">
        </app-equipment-field>
      </div>

      <label class="col-sm-2 control-label">
        Constructeur
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{ detail.secondary_equipment?.model?.brand?.name }}</p>
      </div>
    </div>

    <div *ngIf="detail.type === MerakiTypeEnum.MX">
      <div class="form-group">
        <label class="col-sm-2 control-label" for="clusterNat">
          Cluster mode Nat
        </label>
        <div class="col-sm-4">
          <app-form-select-field name="cluster_mode"
                           [(ngModel)]="detail.cluster_mode"
                           [options]="merakiMXClusterOptions"
                           [disabled]="mode === 'normal'"
                           (ngModelChange)="onChangeFirewallClusterNatCb()">
          </app-form-select-field>
        </div>
        <div *ngIf="detail.cluster_mode !== ClusterMode.None">
          <label class="col-sm-2 control-label">
            Nom du spare
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" [required]="true"
                   [disabled]="mode === 'normal' || !isNameEditable"
                   type="text" [(ngModel)]="detail.spare_name" name="spare_name"/>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="detail.cluster_mode !== ClusterMode.None">
        <label class="col-sm-2 control-label">Equipement secondaire</label>
        <div class="col-sm-4">
          <app-equipment-field [(ngModel)]="detail.secondary_equipment" name="secondary_equipment" [disabled]="mode === 'normal'">
          </app-equipment-field>
        </div>
        <div *ngIf="detail.secondary_equipment">
          <label class="col-sm-2 control-label">SN</label>
          <div class="col-sm-4">
            <p class="form-control-static">{{detail.secondary_equipment.serial_number}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'router'">
      <ng-container>
        <label class="col-sm-2 control-label">
          Lien opérateur <i class="required" aria-hidden="true">*</i>
        </label>
        <div class="col-sm-4">
          <app-operator-line-field class="wcm-highlight-req" [disabled]="mode === 'normal'"
                                   [filters]="{
                                     entity_or_site_b_entity__name_or_code: detail.entity?.code,
                                     offer__type: initialOfferType,
                                     state__in: ['new', 'ordered', 'delivered'],
                                     code__nin: [this.detail.secondary_operator_line?.code]
                                   }"
                                   [disabledColumns]="{entity__name_or_code: true}"
                                   [(ngModel)]="detail.operator_line"
                                   (ngModelChange)="onChangeOperatorLine($event)"
                                   name="operator_line" [required]="true">
          </app-operator-line-field>
        </div>
      </ng-container>
    </div>

    <div class="form-group" *ngIf="['router', 'l2switch'].includes(detail.type)">
      <label class="col-sm-2 control-label">
        Equipement
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="equipment"
                             [(ngModel)]="detail.equipment"
                             [filters]="{model__type_label: getModelTypeLabel(detail.type), entity__code: detail.entity?.code}"
                             [disabled]="mode === 'normal'"
                             [disabledColumns]="{entity__code: true, model__type_label: true}">
        </app-equipment-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'router'">
      <ng-container>
        <label class="col-sm-2 control-label">
          Lien opérateur secondaire
        </label>
        <div class="col-sm-4">
          <app-operator-line-field [disabled]="mode === 'normal' || !detail.operator_line"
                                   [filters]="{
                                      code__nin: [detail.operator_line?.code],
                                      provider_traceability__traceability__code: detail.operator_line?.provider_traceability?.traceability?.code,
                                      entity_or_site_b_entity__name_or_code: detail.entity?.code,
                                      offer__technology__name__in: ['4G', '4G/5G'],
                                      state__in: ['new', 'ordered', 'delivered']
                                   }"
                                   [disabledColumns]="{entity__name_or_code: true}"
                                   [(ngModel)]="detail.secondary_operator_line"
                                   (ngModelChange)="onChangeSecondaryOperatorLine($event)"
                                   name="secondary_operator_line">
          </app-operator-line-field>
        </div>
      </ng-container>
    </div>

    <div class="form-group" *ngIf="detail.type === 'router' && (['4G', '4G/5G'].includes(technology) || ['4G', '4G/5G'].includes(secondary_technology))">
      <label class="col-sm-2 control-label">
        SIM 1
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="first_sim_card_equipment"
                             [(ngModel)]="detail.first_sim_card_equipment"
                             [disabled]="mode === 'normal'"
                             [filters]="firstSimCardFilters"
                             (ngModelChange)="updateSecondSimFilters()">
        </app-equipment-field>
      </div>

      <label class="col-sm-2 control-label">
        SIM 2
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="second_sim_card_equipment"
                             [(ngModel)]="detail.second_sim_card_equipment"
                             [disabled]="mode === 'normal'"
                             [filters]="secondSimCardFilters"
                             (ngModelChange)="updateFirstSimFilters()">
        </app-equipment-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === MerakiTypeEnum.MG">
      <ng-container>
        <label class="col-sm-2 control-label">
          Activer le failover
        </label>
        <div class="col-sm-4">
          <div class="checkbox">
            <p-checkbox [disabled]="mode === 'normal'"
                        [(ngModel)]="detail.activate_fail_over" name="activate_fail_over"
                        [binary]="true" inputId="activate_fail_over">
            </p-checkbox>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="[MerakiTypeEnum.MR, MerakiTypeEnum.MS, MerakiTypeEnum.MX].includes(detail.type)">
      <h3 class="page-header wcm-collapsible-header">
        <span (click)="ipManagmentCollapsed = !ipManagmentCollapsed">
          <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !ipManagmentCollapsed}"></i>
          {{detail.type === MerakiTypeEnum.MX ? 'IP WAN 1' : 'IP Management'}}
        </span>
      </h3>

      <div [ngbCollapse]="ipManagmentCollapsed">
        <div class="form-group">
          <label class="col-sm-2 control-label" for="dhcpCbx">
            DHCP
          </label>
          <div class="col-sm-4">
            <div class="checkbox">
              <p-checkbox [disabled]="mode === 'normal'"
                          [(ngModel)]="detail.dhcp" name="dhcp"
                          (ngModelChange)="cleanHiddenFields(); verifyConsistencyWithIpAndMask()"
                          [binary]="true" inputId="dhcpCbx">
              </p-checkbox>
            </div>
          </div>
          <label class="col-sm-2 control-label">
            VLAN <i class="required" aria-hidden="true" *ngIf="detail.type === 'meraki_ms'">*</i>
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" type="number" min="1"
                   [(ngModel)]="detail.vlan" name="vlan" [required]="true"
                   [disabled]="mode === 'normal'" *ngIf="detail.type === 'meraki_ms'; else elseBlock">
            <ng-template #elseBlock>
              <input class="form-control" type="number" min="1"
                     [(ngModel)]="detail.vlan" name="vlan"
                     [disabled]="mode === 'normal'">
            </ng-template>
          </div>
        </div>

        <div *ngIf="!detail.dhcp">
          <div class="form-group">
            <label class="col-sm-2 control-label">
              IP <i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'"
                     [(ngModel)]="detail.address" name="address" [required]="true"
                     [pattern]="ipPattern" type="text" (ngModelChange)="verifyConsistencyWithIpAndMask()"/>
              <div class="error-message" *ngIf="f.controls.address?.errors?.pattern">
                Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
              </div>
            </div>

            <label class="col-sm-2 control-label">
              Masque CIDR <i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'" type="number"
                     [(ngModel)]="detail.mask" name="mask" [required]="true" min="1" max="32"
                     [pattern]="maskPattern" (ngModelChange)="verifyConsistencyWithIpAndMask()"/>
              <div class="error-message" *ngIf="f.controls.mask?.errors?.pattern">
                La valeur du Masque CIDR n'est pas valide (min=0, max=32)
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">
              Gateway <i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [required]="true"
                     [disabled]="mode === 'normal'" type="text"
                     [(ngModel)]="detail.gateway_address" name="gateway_address"
                     [pattern]="ipPattern"
                     (ngModelChange)="verifyConsistencyWithIpAndMask(false, 'gateway' )"/>
              <div class="error-message" *ngIf="f.controls.gateway_address?.errors?.pattern">
                Le format de la gateway est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
              </div>
              <div class="error-message" *ngIf="consistencyError['major']">
                {{consistencyError['major']}}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">
              DNS principal <i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'" type="text"
                     [(ngModel)]="detail.dns_primary" name="dns_primary" [required]="true"[pattern]="ipPattern"/>
              <div class="error-message" *ngIf="f.controls.dns_primary?.errors?.pattern">
                Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
              </div>
            </div>

            <label class="col-sm-2 control-label">
              DNS secondaire <i *ngIf="detail.dhcp" class="required">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'" type="text"
                     [(ngModel)]="detail.dns_secondary" name="dns_secondary" [required]="detail.dhcp" [pattern]="ipPattern"/>
              <div class="error-message" *ngIf="f.controls.dns_secondary?.errors?.pattern">
                Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="detail.type === MerakiTypeEnum.MX">
        <h3 class="page-header wcm-collapsible-header">
          <span (click)="ipWan2Collapsed = !ipWan2Collapsed">
            <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !ipWan2Collapsed}"></i>
            IP Wan 2
          </span>
        </h3>

        <div [ngbCollapse]="ipWan2Collapsed">
          <div class="form-group">
            <label class="col-sm-2 control-label" for="minor_dhcpCbx">
              DHCP
            </label>
            <div class="col-sm-4">
              <div class="checkbox">
                <p-checkbox [disabled]="mode === 'normal'"
                            [(ngModel)]="detail.minor_dhcp" name="minor_dhcp"
                            (ngModelChange)="cleanHiddenFields(true); verifyConsistencyWithIpAndMask(true);"
                            [binary]="true" inputId="minor_dhcpCbx">
                </p-checkbox>
              </div>
            </div>

            <label class="col-sm-2 control-label">
              VLAN
            </label>
            <div class="col-sm-4">
              <input class="form-control" type="number" min="0"
                     [(ngModel)]="detail.minor_vlan" name="minor_vlan"
                     [disabled]="mode === 'normal'">
            </div>
          </div>

          <div *ngIf="!detail.minor_dhcp">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                IP <i class="required" aria-hidden="true">*</i>
              </label>
              <div class="col-sm-4">
                <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'"
                       [(ngModel)]="detail.minor_address" name="minor_address" [required]="true"
                       [pattern]="ipPattern" type="text" (ngModelChange)="verifyConsistencyWithIpAndMask(true)"/>
                <div class="error-message" *ngIf="f.controls.minor_address?.errors?.pattern">
                  Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
                </div>
              </div>

              <label class="col-sm-2 control-label">
                Masque <i class="required" aria-hidden="true">*</i>
              </label>
              <div class="col-sm-4">
                <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'"
                       [(ngModel)]="detail.minor_mask" name="minor_mask"
                       [required]="true" type="number"
                       [pattern]="maskPattern" (ngModelChange)="verifyConsistencyWithIpAndMask(true)"/>
                <div class="error-message" *ngIf="f.controls.minor_mask?.errors?.pattern">
                  La valeur du Masque CIDR n'est pas valide (min=0, max=32)
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Gateway <i class="required" aria-hidden="true">*</i>
              </label>
              <div class="col-sm-4">
                <input class="form-control wcm-highlight-req" type="text"
                       [disabled]="mode === 'normal' || f.controls.minor_mask?.errors?.pattern || f.controls.minor_address?.errors?.pattern"
                       [(ngModel)]="detail.minor_gateway_address" name="minor_gateway_address"
                       [required]="true" [pattern]="ipPattern" (ngModelChange)="verifyConsistencyWithIpAndMask(true, 'gateway')"/>
                <div class="error-message" *ngIf="f.controls.minor_gateway_address?.errors?.pattern">
                  Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
                </div>
                <div class="error-message" *ngIf="consistencyError['minor']">
                  {{consistencyError['minor']}}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                DNS principal <i class="required" aria-hidden="true">*</i>
              </label>
              <div class="col-sm-4">
                <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'" type="text"
                       [(ngModel)]="detail.minor_dns_primary" name="minor_dns_primary" [required]="true"/>
              </div>

              <label class="col-sm-2 control-label">
                DNS secondaire <i *ngIf="detail.minor_dhcp" class="required">*</i>
              </label>
              <div class="col-sm-4">
                <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'" type="text"
                       [(ngModel)]="detail.minor_dns_secondary" name="minor_dns_secondary" [required]="detail.minor_dhcp" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="page-header wcm-collapsible-header" *ngIf="detail.type === 'firewall'">
      <span (click)="ipManagementCollapsed = !ipManagementCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !ipManagementCollapsed}"></i>
        IP Management
      </span>
    </h3>

    <div *ngIf="detail.type === 'firewall'" [ngbCollapse]=ipManagementCollapsed>
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Adresse IP FW1
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req"
                 name="address" [required]="true" [readonly]="mode === 'normal'"
                 [(ngModel)]="detail.address"
                 (blur)="onAddressChanged(detail.address, 'management_url')" />
        </div>
        <label class="col-sm-2 control-label">
          Accès web FW1
        </label>

        <div class="col-sm-4">
          <!-- handle edition display vs readonly http link -->
          <input class="form-control wcm-highlight-req" *ngIf="mode === 'edition'"
                  name="management_url" [required]="true" [(ngModel)]="detail.management_url" />

          <div class="form-control disabled" *ngIf="mode === 'normal'">
            <a [href]="detail.management_url" title="Ouvrir dans un nouvel onglet"
                class="text-truncated ng-star-inserted"
                target="_blank" role="button">
              {{ detail.management_url }}
            </a>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="detail.cluster_mode === ClusterMode.IndividualIp">
        <label class="col-sm-2 control-label">
          Adresse IP FW2
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req"
                 name="minor_address" [required]="true" [readonly]="mode === 'normal'"
                 [(ngModel)]="detail.minor_address"
                 (blur)="onAddressChanged(detail.minor_address, 'minor_management_url')" />
        </div>
        <div *ngIf="detail.cluster_mode !== ClusterMode.None">
          <label class="col-sm-2 control-label">
            Accès web FW2
          </label>
          <div class="col-sm-4">
            <!-- handle edition display vs readonly http link -->
            <input class="form-control wcm-highlight-req" *ngIf="mode === 'edition'"
                   name="minor_management_url" [required]="true" [(ngModel)]="detail.minor_management_url" />

            <div class="form-control disabled" *ngIf="mode === 'normal'">
              <a [href]="detail.minor_management_url" title="Ouvrir dans un nouvel onglet"
                  class="text-truncated ng-star-inserted"
                  target="_blank" role="button">
                {{ detail.minor_management_url }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="page-header wcm-collapsible-header" *ngIf="['firewall', 'l2switch'].includes(detail.type)">
      <span (click)="supervisionCollapsed = !supervisionCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !supervisionCollapsed}"></i>
        Supervision
      </span>
    </h3>

    <div *ngIf="['firewall', 'l2switch'].includes(detail.type)" [ngbCollapse]=supervisionCollapsed>
      <div class="form-group">
        <label class="col-sm-2 control-label" *ngIf="detail.type === 'firewall'">
          Label monitoring(interne) 1
        </label>
        <label class="col-sm-2 control-label" *ngIf="detail.type === 'l2switch'">
          Label monitoring(interne)
        </label>
        <div class="col-sm-4">
          <input  class="form-control"
                  name="printing_label" [(ngModel)]="detail.printing_label"
                  [readonly]="true"/>
        </div>
        <label class="col-sm-2 control-label" *ngIf="detail.type === 'firewall'">
          Label monitoring (client) 1
        </label>
        <label class="col-sm-2 control-label" *ngIf="detail.type === 'l2switch'">
          Label monitoring (client)
        </label>
        <div class="col-sm-4">
          <input  class="form-control"
                  name="monitoring_label" [(ngModel)]="detail.monitoring_label"
                  [readonly]="true" />
        </div>
      </div>

      <div class="form-group" *ngIf="detail.cluster_mode === ClusterMode.IndividualIp">
        <label class="col-sm-2 control-label">
          Label monitoring(interne) 2
        </label>
        <div class="col-sm-4">
          <input class="form-control"
                 name="minor_printing_label" [(ngModel)]="detail.minor_printing_label"
                 [readonly]="mode === 'normal'" />
        </div>
        <label class="col-sm-2 control-label">
          Label monitoring (client) 2
        </label>
        <div class="col-sm-4">
          <input class="form-control"
                 name="minor_monitoring_label" [(ngModel)]="detail.minor_monitoring_label"
                 [readonly]="mode === 'normal'" />
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Machine de rebond
        </label>
        <div class="col-sm-4">
          <input class="form-control"
                name="proxy_client"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.proxy_client" />
        </div>
        <label class="col-sm-2 control-label">
          Communauté SNMP
        </label>
        <div class="col-sm-4">
          <input class="form-control"
                name="snmp_community"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.snmp_community" />
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Proxy de supervision<i class="required" aria-hidden="true">*</i>
        </label>
        <div class="col-sm-4">
          <app-input-list-field [(ngModel)]="detail.proxy_monitoring_name" name="proxy_monitoring_name" class="wcm-highlight-req"
            [required]="true" *ngIf="existingProxies" [disabled]="mode === 'normal'" [list]="existingProxies" (ngModelChange)="onProxyUpdate()">
          </app-input-list-field>
        </div>

        <label class="col-sm-2 control-label" *ngIf="detail.type === 'l2switch'">
          IP de supervision<i class="required" aria-hidden="true">*</i>
        </label>
        <div class="col-sm-4" *ngIf="detail.type === 'l2switch'">
          <input class="form-control wcm-highlight-req"
                name="address"
                [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.address" />
        </div>
      </div>
    </div>

    <ng-container *ngIf="detail.zabbix_id || detail.minor_zabbix_id">
      <hr />

      <div class="form-group">
        <ng-container *ngIf="detail.zabbix_id">
          <label class="col-sm-2 control-label" *ngIf="detail.type === 'firewall'">
            Groupes Zabbix FW 1
          </label>
          <label class="col-sm-2 control-label" *ngIf="detail.type === 'l2switch'">
            Groupes Zabbix
          </label>
          <div class="col-sm-4">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" [hidden]="!zabbixLoading"></i>
            <app-lines-detail-zabbix-field name="zabbixHostGroupsPrimary" [disabled]="mode === 'normal'"
              [canAddHostGroup]="true" [source]="api.zabbix_groups" [(ngModel)]="detail.zabbixHostGroupsPrimary">
            </app-lines-detail-zabbix-field>
          </div>
        </ng-container>

        <ng-container *ngIf="detail.minor_zabbix_id">
          <label class="col-sm-2 control-label">
            Groupes Zabbix FW 2
          </label>
          <div class="col-sm-4">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" [hidden]="!zabbixLoading"></i>
            <app-lines-detail-zabbix-field [disabled]="mode === 'normal'"
              [(ngModel)]="detail.zabbixHostGroupsSecondary" [source]="api.zabbix_groups"
              [canAddHostGroup]="true" name="zabbixHostGroupsSecondary">
            </app-lines-detail-zabbix-field>
          </div>
        </ng-container>
      </div>

      <div class="form-group">
        <ng-container *ngIf="detail.zabbix_id">
          <label class="col-sm-2 control-label" *ngIf="detail.type === 'firewall'">
            Templates FW 1
          </label>
          <label class="col-sm-2 control-label" *ngIf="detail.type === 'l2switch'">
            Templates
          </label>
          <div class="col-sm-4">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" [hidden]="!zabbixLoading"></i>
            <app-lines-detail-zabbix-field name="zabbixHostTemplatesPrimary" [disabled]="mode === 'normal'"
              [source]="api.zabbix_templates" [canAddHostGroup]="false" [(ngModel)]="detail.zabbixHostTemplatesPrimary">
            </app-lines-detail-zabbix-field>
          </div>
        </ng-container>

        <ng-container *ngIf="detail.minor_zabbix_id">
          <label class="col-sm-2 control-label">
            Templates FW 2
          </label>
          <div class="col-sm-4">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" [hidden]="!zabbixLoading"></i>
            <app-lines-detail-zabbix-field name="zabbixHostTemplatesSecondary" [disabled]="mode === 'normal'"
              [source]="api.zabbix_templates" [canAddHostGroup]="false" [(ngModel)]="detail.zabbixHostTemplatesSecondary">
            </app-lines-detail-zabbix-field>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="detail.type === MerakiTypeEnum.MG">
      <h3 class="page-header wcm-collapsible-header">
        <span (click)="simOneCollapsed = !simOneCollapsed">
          <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !simOneCollapsed}"></i>
          SIM 1
        </span>
      </h3>

      <div [ngbCollapse]=simOneCollapsed>
        <div class="form-group">
          <label class="col-sm-2 control-label" for="sim_1_apn_default">
            APN default
          </label>
          <div class="col-sm-4">
            <div class="checkbox">
              <p-checkbox [disabled]="mode === 'normal'"  (ngModelChange)="onChangeAPNDefaultOne()"
                          [(ngModel)]="detail.sim_1_apn_default" name="sim_1_apn_default"
                          [binary]="true" inputId="sim_1_apn_default">
              </p-checkbox>
            </div>
          </div>
          <label class="col-sm-2 control-label" for="sim_1_primary">
            Primaire
          </label>
          <div class="col-sm-4">
            <div class="checkbox">
              <p-checkbox [disabled]="mode === 'normal'"
                          [(ngModel)]="detail.sim_1_primary" name="sim_1_primary"
                          [binary]="true" inputId="sim_1_primary">
              </p-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="!detail.sim_1_apn_default">
          <label class="col-sm-2 control-label">
            APN Name<i class="required" aria-hidden="true">*</i>
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'"
                   type="text" [(ngModel)]="detail.sim_1_apn_name" name="sim_1_apn_name" [pattern]="formatAPNName" [required]="true"/>
            <div class="error-message" *ngIf="f.controls.sim_1_apn_name?.errors?.pattern && mode === 'edition'">
              Format du nom APN invalide. Le nom peut contenir des lettres, des nombres et les caractères suivants - .
            </div>
          </div>

          <label class="col-sm-2 control-label">
            IP Type<i class="required" aria-hidden="true">*</i>
          </label>
          <div class="col-sm-4">
            <ng-container>
              <select [(ngModel)]="detail.sim_1_ip_type" class="form-control"
                      name="sim_1_ip_type"
                      [disabled]="mode === 'normal'" [required]="true">
                      <option [ngValue]="'IPv4'">IPv4</option>
                      <option [ngValue]="'IPv6'">IPv6</option>
                      <option [ngValue]="'IPv4 et 6'">IPv4 et IPv6</option>
              </select>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Lien opérateur
          </label>
          <div class="col-sm-4">
            <app-operator-line-field class="wcm-highlight-req" [disabled]="mode === 'normal' || detail.line_count"
                                     [filters]="{entity_or_site_b_entity__name_or_code: detail.entity?.code, offer__technology__name: 'Mobile', state__in: ['new', 'ordered', 'delivered'], code__nin: SimTwoOperatorLineCode}"
                                     [disabledColumns]="{entity__name_or_code: true}"
                                     [(ngModel)]="detail.sim_1_operator_line"
                                     (ngModelChange)="onChangeOperatorLineSimOne($event)"
                                     name="sim_1_operator_line">
            </app-operator-line-field>
          </div>
        </div>
      </div>

      <div *ngIf="detail.type === MerakiTypeEnum.MG">
        <h3 class="page-header wcm-collapsible-header">
          <span (click)="simTwoCollapsed = !simTwoCollapsed">
            <i class="fa fa-chevron-right chevron-rotate rotate-animation" aria-hidden="true" [ngClass]="{'rotate': !simTwoCollapsed}"></i>
            SIM 2
          </span>
        </h3>
        <div [ngbCollapse]=simTwoCollapsed>
          <div class="alert alert-warning">
            Pour un Meraki MG sans seconde carte SIM veuillez laisser coché la case APN défaut
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label" for="sim_2_apn_default">
              APN default
            </label>
            <div class="col-sm-4">
              <div class="checkbox">
                <p-checkbox [disabled]="mode === 'normal'" (ngModelChange)="onChangeAPNDefaultTwo()"
                            [(ngModel)]="detail.sim_2_apn_default" name="defaultValueSim2ApnDefault"
                            [binary]="true" inputId="sim_2_apn_default">
                </p-checkbox>
              </div>
            </div>
            <label class="col-sm-2 control-label" for="sim_2_primary">
              Primaire
            </label>
            <div class="col-sm-4">
              <div class="checkbox">
                <p-checkbox [disabled]="mode === 'normal'"
                            [(ngModel)]="detail.sim_2_primary" name="sim_2_primary"
                            [binary]="true" inputId="sim_2_primary">
                </p-checkbox>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="!detail.sim_2_apn_default">
            <label class="col-sm-2 control-label">
              APN Name<i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" [disabled]="mode === 'normal'"
                     type="text" [(ngModel)]="detail.sim_2_apn_name" name="sim_2_apn_name" [pattern]="formatAPNName" [required]="true"/>
              <div class="error-message" *ngIf="f.controls.sim_2_apn_name?.errors?.pattern && mode === 'edition'">
                Format du nom APN invalide. Le nom peut contenir des lettres, des nombres et les caractères suivants - .
              </div>
            </div>

            <label class="col-sm-2 control-label">
              IP Type<i class="required" aria-hidden="true">*</i>
            </label>
            <div class="col-sm-4">
              <ng-container>
                <select [(ngModel)]="detail.sim_2_ip_type" class="form-control"
                        name="sim_2_ip_type"
                        [disabled]="mode === 'normal'" [required]="true">
                        <option [ngValue]="'IPv4'">IPv4</option>
                        <option [ngValue]="'IPv6'">IPv6</option>
                        <option [ngValue]="'IPv4 & Ipv6'">IPv4 & IPv6</option>
                </select>
              </ng-container>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">
              Lien opérateur
            </label>
            <div class="col-sm-4">
              <app-operator-line-field class="wcm-highlight-req" [disabled]="mode === 'normal' || detail.line_count"
                                       [filters]="{entity_or_site_b_entity__name_or_code: detail.entity?.code, offer__technology__name: 'Mobile', state__in: ['new', 'ordered', 'delivered'], code__nin : SimOneOperatorLineCode}"
                                       [disabledColumns]="{entity__name_or_code: true}"
                                       [(ngModel)]="detail.sim_2_operator_line"
                                       (ngModelChange)="onChangeOperatorLineSimTwo($event)"
                                       name="sim_2_operator_line">
              </app-operator-line-field>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0" *ngIf="![MerakiTypeEnum.MG, MerakiTypeEnum.MR, MerakiTypeEnum.MS, 'firewall'].includes(detail.type)">
        <a ngbNavLink>Configuration LAN</a>
        <ng-template ngbNavContent>
          <app-vrf-lan-assigned-subnets-list [networkDevice]="detail"
                                              [disableNoResultDisplay]="true" [hidePaginator]="true"
                                              [disableUrlUpdate]="true"  [disableFiltersDisplay]="true">
          </app-vrf-lan-assigned-subnets-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1" *ngIf="detail.type === 'router'">
        <a ngbNavLink>Configuration WAN</a>
        <ng-template ngbNavContent>
          <app-lines-list [isNDInactive]='!detail.is_active'
                          [hasSecondaryOperatorLine]="detail.secondary_operator_line !== null"
                          [hasSecondaryLine]="detail.has_secondary_line"
                          [hasDataLine]="detail.has_data_line"
                          [isEditing]="mode === 'edition'"
                          [filters]="linesOpt.filters"
                          (createFunction)="createNewLine()" (createSecondaryLineFunction)="createSecondaryLine()"
                          [disabledButtons]="linesOpt.disabledButtons"
                          [disabledColumns]="linesOpt.disabledColumns"
                          [disableUrlUpdate]="true" *ngIf="tabsStatus[1]">
          </app-lines-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique des workflow</a>
        <ng-template ngbNavContent>
          <app-workflow-histories-list [id]="detail.id" model="network-device" *ngIf="tabsStatus[2]">
          </app-workflow-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Équipement</a>
        <ng-template ngbNavContent>
           <app-network-devices-equipments-history [filters]="equipmentsHistoryOpt.filters" [id]="detail.id"
                                                   model="network-device-equipment-relations" *ngIf="tabsStatus[3]">
           </app-network-devices-equipments-history>
        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="networkdevice" [id]="detail.id" *ngIf="tabsStatus[4]"></app-model-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="5">
        <a ngbNavLink>Maintenances</a>
        <ng-template ngbNavContent>
          <app-argos-event-mutes-list [filters]="argosEventMutesOpt.filters" [id]="detail.id" *ngIf="tabsStatus[5]"
                                      [disabledColumns]="argosEventMutesOpt.disabledColumns" >
          </app-argos-event-mutes-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="'comments'">
        <a ngbNavLink>Discussion <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
        <ng-template ngbNavContent>
          <app-comments model="network-device" [pk]="detail.id" [autoRefresh]="true"></app-comments>
        </ng-template>
      </li>

    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
