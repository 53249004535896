<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal' && hasPermissions('Antoine:BackboneEquipmentCanCreate')">
        <button class="btn btn-default" type="button" (click)="addMaintenance()"
          *ngIf="hasPermissions('Argos:MaintenanceWindowCanCreate')">
          Ajouter une maintenance
        </button>

        <button type="button" class="btn" [ngClass]="detail.is_active ? 'btn-danger' : 'btn-primary'" (click)="toogleIsActive()">
          <span *ngIf="!detail.is_active">Activer</span>
          <span *ngIf="detail.is_active">Désactiver</span>
        </button>

        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition' && hasPermissions('Antoine:BackboneEquipmentCanCreate')">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <app-argos-event-mutes-alert [backboneEquipment]="detail"></app-argos-event-mutes-alert>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Communauté SNMP
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="snmp_community"
                pattern="^[\w-]{1,31}$"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.snmp_community" />

        <div class="error-message" *ngIf="f.controls.snmp_community?.errors?.pattern">
          Format de la Communauté SNMP invalide. Seules les lettres, les chiffres et les symboles tiret(-) et les tiret bas(_) sont acceptés, avec un maximum de 31 caractères.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom DNS <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="dns" [required]="true"
                pattern="^[a-z0-9\-\.]+$"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.dns" />

        <div class="error-message" *ngIf="f.controls.dns?.errors?.pattern">
          Format du nom DNS invalide. Seules les lettres minuscules, les chiffres et les symboles tiret(-) et point(.) sont acceptés.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Datacenter <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field [(ngModel)]="detail.datacenter" name="datacenter"
                          [required]="true" class="wcm-highlight-req"
                          [disabled]="mode === 'normal'">
        </app-entity-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize" [disabled]="mode === 'normal'"
                  name="description" [(ngModel)]="detail.description" rows="3">
      </textarea>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Liens opérateur</a>
        <ng-template ngbNavContent>
          <app-operator-lines-list [disableUrlUpdate]="true" *ngIf="tabsStatus[0]"
                                   [filters]="operatorLinesList.filters"
                                   [disabledColumns]="operatorLinesList.disabledColumns"
                                   [disabledButtons]="operatorLinesList.disabledButtons" >
          </app-operator-lines-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Lignes réseau WAN</a>
        <ng-template ngbNavContent>
          <app-lines-list [disableUrlUpdate]="true" *ngIf="tabsStatus[1]"
                          [disabledColumns]="linesList.disabledColumns"
                          [filters]="linesList.filters">
          </app-lines-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="backboneequipment" [id]="detail.id" *ngIf="tabsStatus[2]"></app-model-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Maintenances</a>
        <ng-template ngbNavContent>
          <app-argos-event-mutes-list [disableUrlUpdate]="true" [filters]="argosEventMutesList.filters" *ngIf="tabsStatus[3]">
          </app-argos-event-mutes-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
