<div [ngSwitch]="model">
  <ng-container *ngSwitchCase="'entity'">
    <a routerLink="/entities/detail/{{object.code}}">{{object.name}} / {{object.code}}</a><br>
    <small><span class="label inverted-default">Réf. client</span> {{object.customer_ref}}</small><br>
    <small><span class="label inverted-default">Adresse</span> {{object.location?.address}} - {{object.location?.city}}</small><br>
    <small><span class="label inverted-default">Id. légal</span> {{object.company_identifier}}</small><br>
    <small>
      <span class="label inverted-default">Accès rapides</span>
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 3}">Contacts</a> -
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 6}">Devis</a> -
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 7}">Commandes</a> -
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 9}">Services</a> -
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 8}">Factures</a> -
      <a routerLink="/entities/detail/{{object.code}}" [queryParams]="{active_tab: 12}">Équipements</a>
    </small><br>
    <small *ngIf="object.parent">
      <span class="label inverted-default">Parent</span>
      <a routerLink="/entities/detail/{{object.parent?.code}}">{{object.parent?.name}}</a> -
      <a routerLink="/entities/detail/{{object.parent?.code}}">{{object.parent?.code}}</a>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'equipment'">
    <a routerLink="/equipments/detail/{{object.code}}">{{object.code}} <span *ngIf="object.number">/ {{object.number}}</span></a><br>
    <small><span class="label inverted-default">Modèle</span> {{object.model?.name}}</small><br>
    <small><span class="label inverted-default">Réf. constructeur</span> {{object.model?.branded_name}}</small><br>
    <small><span class="label inverted-default">SN</span> {{object.serial_number}}</small><br>
    <small><span class="label inverted-default">Affectation</span> {{translatedLocations[object.location] || object.location}}</small><br>
    <small *ngIf="object.entity?.code">
      <span class="label inverted-default">Site d'installation</span>
      <a routerLink="/entities/detail/{{object.entity?.code}}" [queryParams]="{active_tab: 11}">{{object.entity?.name}} ({{object.entity?.code}})</a>
    </small><br>
    <small *ngIf="object.reserved_for?.code">
      <span class="label inverted-default">Réservé pour</span>
      <a routerLink="/entities/detail/{{object.reserved_for?.code}}">{{object.reserved_for?.name}} ({{object.reserved_for?.code}})</a>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'invoice'">
    <a routerLink="/invoices/detail/{{object.code}}">{{object.number || object.code}}</a><br>
    <small><span class="label inverted-default">Réf. client</span> {{object.customer_ref}}</small><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity?.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small><span class="label inverted-default">Date</span> {{object.date | amDateFormat:'L'}}</small><br>
    <small>
      <span class="label inverted-default">Envoyée le</span>
      {{object.invoice_send_item?.date_sent | amDateFormat:'L'}} <span *ngIf="object.invoice_send_item?.type">par {{object.invoice_send_item?.type}}</span>
    </small><br>
    <small>
      <span class="label inverted-default">Statut</span>
      <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'line'">
    <a routerLink="/lines/detail/{{object.code}}">{{object.external_label}} / {{object.code}}</a><br>
    <small><span class="label inverted-default">Login PPP</span> {{object.login}}</small><br>
    <small><span class="label inverted-default">IP WAN</span> {{object.wan_ip}}</small><br>
    <small><span class="label inverted-default">Equipement Réseau</span> <a routerLink="/network-devices/detail/{{object.network_device?.code}}">{{object.network_device?.code}}</a></small><br>
    <small><span class="label inverted-default">VRF</span> <a routerLink="/vrfs/detail/{{object.server_vrf?.id}}">{{object.server_vrf?.name}}</a></small><br>
    <small><span class="label inverted-default">QOS</span> {{object.qos}}</small><br>
    <small><span class="label inverted-default">Livrée le</span> {{object.delivery_date | amDateFormat:'L'}}</small><br>
    <small><span class="label inverted-default">Client</span> <a routerLink="/entities/detail/{{object.network_device?.entity?.code}}" [queryParams]="{active_tab: 19}">{{object.network_device?.entity?.name}}  / {{object.network_device?.entity?.code}}</a></small><br>
  </ng-container>

  <ng-container *ngSwitchCase="'network-device'">
    <a routerLink="/network-devices/detail/{{object.code}}">{{object.code}} / {{object.monitoring_label}}</a><br>
    <small><span class="label inverted-default">Type</span> {{networkDeviceTypes[object.type] || object.type}}</small><br>
    <small><span class="label inverted-default">Client</span>
      <a routerLink="/entities/detail/{{object.entity?.code}}" [queryParams]="{active_tab: 19}">{{object.entity?.name}} / {{object.entity?.code}}</a>
    </small><br>
    <small><span class="label inverted-default">Numéro</span> {{object.number}}</small><br>
    <small><span class="label inverted-default">IPs LAN</span> {{ipsStr}}</small><br>
  </ng-container>

  <ng-container *ngSwitchCase="'operator-line'">
    <a routerLink="/operator-lines/detail/{{object.code}}">{{object.code}}</a><br>
    <small><span class="label inverted-default">Réf. technique</span> {{object.technical_ref}}</small><br>
    <small><span class="label inverted-default">Réf de commande</span> {{object.order_ref}}</small><br>
    <small><span class="label inverted-default">Client</span>
      <a routerLink="/entities/detail/{{object.entity?.code}}">{{object.entity?.name}} / {{object.entity?.code}}</a>
    </small><br>
    <small><span class="label inverted-default">Services Fournisseur</span> {{providertraceabilitiesStr}}</small><br>
    <small>
      <span class="label inverted-default">Equipements Réseau</span>
      <span *ngFor="let networkDeviceCode of object.network_devices; let last = last">
        <a routerLink="/network-devices/detail/{{networkDeviceCode}}">{{networkDeviceCode}}</a><span *ngIf="!last">,</span>
      </span>
    </small><br>
  </ng-container>

  <ng-container *ngSwitchCase="'order'">
    <a routerLink="/orders-mrp/detail/{{object.code}}">{{object.code}}</a><br>
    <small><span class="label inverted-default">N° devis</span> {{object.internal_ref}}</small><br>
    <small><span class="label inverted-default">Réf. client</span> {{object.customer_ref}}</small><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small><span class="label inverted-default">Date</span> {{object.date | amDateFormat:'L'}}</small><br>
    <small><span class="label inverted-default">Commercial</span> <app-wcm-user [username]="object.salesperson"></app-wcm-user></small><br>
    <small><span class="label inverted-default">Total</span> {{object.total}}€</small><br>
    <small><span class="label inverted-default">Opportunité</span> {{object.sf_opportunity_name}}</small><br>
    <small>
      <span class="label inverted-default">Statut</span>
      <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'product-configuration'">
    {{object.label}}<br>
    <small><span class="label inverted-default">Code</span> {{object.code}}</small><br>
    <small>
      <span class="label inverted-default">Produit</span>
      <a routerLink="/products/detail/{{object.product?.code}}" [queryParams]="{active_tab: 2}">{{object.product?.code}} / {{object.product?.name}}</a> <br>
      <span class="label inverted-default">Prix MRC</span> <span class="danger">{{object.price_public_mrc}}€</span> <br>
      <span class="label inverted-default">Prix NRC</span> {{object.price_public_nrc}}€
    </small><br>
  </ng-container>

  <ng-container *ngSwitchCase="'provider-traceability'">
    <a routerLink="/traceabilities/detail/{{object.traceability.code}}">{{object.code}} - {{object.external_ref}}</a><br>
    <small><span class="label inverted-default">Nom</span> {{object.name}}</small><br>
    <small><span class="label inverted-default">Réf externe</span> {{object.external_ref}}</small><br>
    <small><span class="label inverted-default">Fournisseur</span> {{object.provider_name}}</small><br>
    <small><span class="label inverted-default">Offre</span> {{object.offer?.name}}</small><br>
    <small><span class="label inverted-default">Activé le</span> {{object.activation_date | amDateFormat:'L'}}</small><br>
    <small><span class="label inverted-default">Résilié le</span> {{object.cancellation_date | amDateFormat:'L'}}</small>
  </ng-container>

  <ng-container *ngSwitchCase="'quote'">
    <a routerLink="/quotes/detail/{{object.code}}">{{object.code}} <span *ngIf="object.number">/ {{object.number}}</span></a><br>
    <small><span class="label inverted-default">Réf. client</span> {{object.customer_ref}}</small><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity?.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small><span class="label inverted-default">Date</span> {{object.date | amDateFormat:'L'}}</small><br>
    <small><span class="label inverted-default">Commercial</span> <app-wcm-user [username]="object.salesperson"></app-wcm-user></small><br>
    <small><span class="label inverted-default">Opportunité</span> {{object.sf_opportunity_name}}</small><br>
    <small>
     <span class="label inverted-default">Statut</span>
     <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'traceability'">
    <a routerLink="/traceabilities/detail/{{object.code}}">{{object.code}} - {{object.product?.name}}</a><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity?.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small *ngIf="object.location"><span class="label inverted-default">Adresse</span> {{object.location?.address}} - {{object.location?.city}}</small><br>
    <small>
      <span class="label inverted-default">Statut</span>
      <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'work-order'">
    <a routerLink="/work-orders/detail/{{object.code}}">{{object.code}} - {{object.title}}</a><br>
    <small>
      <span class="label inverted-default">Commande</span>
      <a routerLink="/orders-mrp/detail/{{object.order?.code}}">{{object.order?.code}} - {{object.order?.internal_ref}}</a>
    </small><br>
    <small>
      <span class="label inverted-default">Service</span>
      <a routerLink="/traceabilities/detail/{{object.traceability?.code}}">{{object.traceability?.code}}</a>
    </small><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity?.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small><span class="label inverted-default">Date</span> {{object.date | amDateFormat:'L'}}</small><br>
    <small>
      <span class="label inverted-default">Statut</span>
      <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small>
  </ng-container>

  <ng-container *ngSwitchCase="'work-order-item'">
    <a routerLink="/work-order-items/detail/{{object.code}}">{{object.code}} - {{object.title}}</a><br>
    <small><span class="label inverted-default">Produit</span> {{object.product?.name}}</small><br>
    <small><span class="label inverted-default">Client</span>
      {{object.entity?.name}} <span *ngIf="object.entity?.customer_ref">/ {{object.entity?.customer_ref}}</span>
    </small><br>
    <small><span class="label inverted-default">Créée le</span> {{object.date | amDateFormat:'L'}}</small><br>
    <small><span class="label inverted-default">Responsable</span> <app-wcm-user [username]="object.assignee"></app-wcm-user></small><br>
    <small><span class="label inverted-default">Description</span>
      <span class="line-clamp">{{object.note}}</span>
    </small><br>
    <small>
     <span class="label inverted-default">Statut</span>
     <span class="label label-{{object.state?.style}}">{{object.state?.label}}</span>
    </small><br>
    <small><span class="label inverted-default" *ngIf="object.external_ref">Référence externe</span> {{object.external_ref}}</small>
  </ng-container>

  <ng-container *ngSwitchCase="'comment'">
    <a routerLink="{{commentTypeDict[object.content_type?.model]?.baseUrl + object.content_id}}" [queryParams]="{active_tab: 'comments'}">
      {{(commentTypeDict[object.content_type?.model]?.label || object.content_type) + ' (' + (object.content?.code || object.content_id) + ')'}}
    </a><br>
    <small><span class="label inverted-default">Contenu</span>
      <span class="line-clamp">{{object.text}}</span>
    </small><br>
    <small><span class="label inverted-default">Auteur</span> <app-wcm-user [username]="object.author"></app-wcm-user></small>

  </ng-container>

  <ng-container *ngSwitchCase="'contact'">
    <a routerLink="/contacts/detail/{{object.contact?.code}}">{{object.contact?.code}} / {{object.contact?.first_name}} {{object.contact?.last_name}}</a><br>
    <small><span class="label inverted-default">Fonction</span> {{contactFunctions[object.function]}}</small><br>

    <small *ngIf="object.entity">
      <span class="label inverted-default">Entité</span>
      <a routerLink="/entities/detail/{{object.entity?.code}}" [queryParams]="{active_tab: 3}">{{object.entity?.name}} ({{object.entity?.code}})</a>
    </small><br>

    <small><span class="label inverted-default">Email</span> {{object.contact?.email}}</small><br>
    <small><span class="label inverted-default">Fax</span> {{object.contact?.fax}}</small><br>
    <small><span class="label inverted-default">Téléphone</span> {{object.contact?.phone}}</small><br>
    <small><span class="label inverted-default">Mobile</span> {{object.contact?.mobile}}</small><br>
  </ng-container>
</div>
