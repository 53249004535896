<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>

      <div *ngIf="mode === 'normal'">
        <app-notification-dropdown *ngIf="detail.id" [pk]="detail.id"
              model="Equipment" style="margin-right: 5px;">
        </app-notification-dropdown>
        <button type="button" class="btn btn-default" (click)="edit()" *ngIf="hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin')">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code && hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin')">
          Créer
        </button>


        <button type="button" class="btn btn-default" (click)="save(true)"
                *ngIf="!detail.id && hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin') && !disabledButtonCreateAdd"
                [disabled]="f.invalid || loading">
          Créer puis ajouter nouveau
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()" *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code && hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin')"
                  [disabled]="f.invalid || loading">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group" *ngIf="detail.code">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Dernière activité
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.last_stock_activity | amDateFormat:'L LT'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Commande fournisseur
      </label>
      <div class="col-sm-4">
        <app-provider-order-field name="providerOrder"
                                  [(ngModel)]="detail.provider_order"
                                  [disabled]="mode === 'normal'"
                                  (ngModelChange)="onProviderOrderUpdate()">
        </app-provider-order-field>
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.id">
        Créé le
      </label>
      <div class="col-sm-4" *ngIf="detail.id">
        <p class="form-control-static"> {{ detail.created_at| amDateFormat:'L' }}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Modèle <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-equipment-model-field class="wcm-highlight-req"
                                   name="model"
                                   [(ngModel)]="detail.model"
                                   [filters]="equipementModelFilters"
                                   [required]="true"
                                   [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')"
                                   (ngModelChange)="onEquipmentModelUpdate()">
        </app-equipment-model-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        N° de série <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <div *ngIf="detail.id">
          <input class="form-control wcm-highlight-req"
                 name="serialNumber"
                 [(ngModel)]="detail.serial_number"
                 (ngModelChange)="onSerialNumberUpdate()"
                  [readonly]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')" />
        </div>
        <div *ngIf="!detail.id">
          <textarea modal
                    class="form-control wcm-highlight-req"
                    name="serialNumberId"
                    [(ngModel)]="detail.serial_number"
                    (ngModelChange)="onSerialNumberUpdate()"
                    placeholder="{{'AAAA1234\nBBBB2345\nCCCC3456'}}"
                    rows="5"
                    [required]="true"
                    [disabled]="mode === 'normal'">
          </textarea>
        </div>
      </div>

      <label class="col-sm-2 control-label">
        SN Interne
      </label>
      <div class="col-sm-4">
        <input class="form-control"
               name="internal_serial_number"
               [(ngModel)]="detail.internal_serial_number"
               [readonly]="mode === 'normal'" />
      </div>
    </div>

    <div class="form-group">
      <div *ngIf="!detail.id && hasPermissions('Wira:EqpAdmin') && counts.updated">
        <label class="col-sm-2 control-label">
          Suivi commande
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            <span class="label label-{{counts.status || 'default'}}">
              {{counts.checked_eqp}} produits / {{counts.ordered_eqp}} commandés
            </span>
          </p>
        </div>
      </div>

      <div *ngIf="detail.id && detail.model && detail.model.need_mac_address">
        <label class="col-sm-2 control-label">
          Adresse MAC <i class="required" *ngIf="detail.location === EquipmentLocationEnum.Shop">*</i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req"
                 type="text"
                 name="macAddress"
                 placeholder="ex: 00:11:22:aa:bb:cc"
                 [(ngModel)]="detail.mac_address"
                 [readonly]="mode === 'normal'"
                 [pattern]="macPattern"
                 [required]="detail.location === EquipmentLocationEnum.Shop" />
          <span class="error-message" *ngIf="f.controls?.macAddress?.errors?.pattern">
            Le format de l'adresse MAC est incorrect.<br>
            Exemple de valeur attendue : (00:11:22:aa:bb:cc ou 001122aabb ou 00-11-22-aa-bb ou 00 11 22 aa bb)
          </span>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="!detail.id && hasPermissions('Wira:EqpCanManageNoSN')">
      <div class="col-sm-offset-2 col-sm-4">
        <button class="btn btn-default full-width wrap-text" (click)="addNoSerialNumberEquipmentModal()">
          Ajouter des équipements sans n° de série
        </button>
      </div>
    </div>

    <div class="form-group" *ngIf="!detail.id && detail.serial_number">
      <div class="col-sm-offset-2 col-sm-4">
        <div class="input-group">
          <input class="form-control"
                 name="prefix"
                 type="text"
                 placeholder="Préfixe à retirer (ex: 'E-')"
                 [(ngModel)]="prefix"
                 [readonly]="mode === 'normal'" />
          <span class="input-group-btn">
            <button class="btn btn-danger" (click)="removePrefix()"
                    [disabled]="!detail.serial_number || !prefix">Retirer le préfixe</button>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Tags
      </label>
      <div class="col-sm-4">
        <app-tags-field name="tags"
                        [(ngModel)]="detail.tags"
                        [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')">
        </app-tags-field>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site Logistique
      </label>
      <div class="col-sm-4">
        <app-logistics-site-field name="logistics_site"
                                  [(ngModel)]="logisticsSite"
                                  [disabled]="mode === 'normal'"
                                  (ngModelChange)="onLogisticsSiteChanged()">
        </app-logistics-site-field>
      </div>

      <label class="col-sm-2 control-label">
        Propriétaire <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control"
                name="owner"
                [(ngModel)]="detail.owner"
                [required]="true"
                [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')"
                (ngModelChange)="onOwnerChanged()">
          <option *ngFor="let opt of ownerOptions | keyvalue" [ngValue]="opt.key">{{opt.value}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Emplacement
      </label>
      <div class="col-sm-4">
        <p *ngIf="mode === 'edition' && (!logisticsSite || !detail.owner)" class="form-control-static text-muted">
          Le site logistique et le propriétaire sont requis pour sélectionner un emplacement.
        </p>
        <app-equipment-location-field *ngIf="logisticsSite && detail.owner"
                                      name="equipment_location"
                                      [(ngModel)]="detail.equipment_location"
                                      [filters]="equipmentLocationOptions.filters"
                                      [disabledColumns]="equipmentLocationOptions.disabledColumns"
                                      [disabled]="mode === 'normal' || !logisticsSite || !detail.owner">
        </app-equipment-location-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site d'installation
      </label>
      <div class="col-sm-4">
        <app-entity-field name="entity"
                          [ngModel]="detail.entity"
                          [disabled]="mode === 'normal'"
                          (ngModelChange)="onEntityUpdate($event)">
        </app-entity-field>
      </div>

      <label class="col-sm-2 control-label">
        Affectation <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control"
                name="location"
                [(ngModel)]="detail.location"
                [required]="true"
                [disabled]="mode === 'normal'"
                (ngModelChange)="onChangeLocation($event)">
          <option *ngFor="let opt of locationOptions | keyvalue" [value]="opt.key">{{opt.value}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Numéro projet
      </label>
      <div class="col-sm-4">
        <input class="form-control"
               name="project_number"
               [(ngModel)]="detail.project_number"
               [readonly]="mode === 'normal'" />
      </div>

      <label class="col-sm-2 control-label">
        Réservé pour
      </label>
      <div class="col-sm-4">
        <app-entity-field name="reservedFor"
                          [(ngModel)]="detail.reserved_for"
                          [filters]="reservedForList.filters"
                          [disabledButtons]="reservedForList.disabledButtons"
                          [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')">
        </app-entity-field>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Prix d'achat unitaire <br> (H.T.)
      </label>
      <div class="col-sm-4">
        <app-decimal-field class="wcm-highlight-req"
                           name="priceUntaxed"
                           pattern="^\d*\.?\d{0,2}$"
                           [(ngModel)]="detail.price_untaxed"
                           [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')"
                           [maxDecimalPlaces]="2">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls.price_untaxed?.errors?.pattern">
          Prix invalide. Au maximum deux décimales sont autorisées.
        </div>
      </div>
      <label class="col-sm-2 control-label">
        Prix d'achat licence <br> (H.T.)
      </label>
      <div class="col-sm-4">
        <app-decimal-field class="wcm-highlight-req"
                           name="licensePrice"
                           pattern="^\d*\.?\d{0,2}$"
                           [(ngModel)]="detail.license_price"
                           [disabled]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')"
                           [maxDecimalPlaces]="2">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls.price_untaxed?.errors?.pattern">
          Prix invalide. Au maximum deux décimales sont autorisées.
        </div>
      </div>

    </div>

    <div class="form-group">
      <div *ngIf="detail.code">
        <label class="col-sm-2 control-label">
          Équipement comptable
        </label>
        <div class="col-sm-4">
          <app-accounting-equipment-field name="accountingEquipment"
                                          [(ngModel)]="detail.accounting_equipment"
                                          [disabled]="true">
          </app-accounting-equipment-field>
        </div>
      </div>
      <!-- old provider order number used before the provider order object -->
      <label class="col-sm-2 control-label">
        N° de commande fournisseur
      </label>
      <div class="col-sm-4">
        <input class="form-control"
               name="providerOrderNumber"
               [(ngModel)]="detail.provider_order_number"
               [readonly]="mode === 'normal'" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{ detail.model?.type_label }}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        N° d'immobilisation
      </label>
      <div class="col-sm-4">
        <input class="form-control"
               name="immobilizationNumber"
               [(ngModel)]="detail.immobilization_number"
               [readonly]="mode === 'normal' || !hasPermissions('Wira:EqpAdmin')" />
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="'comments'">
        <a ngbNavLink>Discussion <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
        <ng-template ngbNavContent>
          <app-comments model="equipment" [pk]="detail.id"></app-comments>
        </ng-template>
      </li>

      <li [ngbNavItem]="0" *ngIf="recordList">
        <a ngbNavLink>Historique <span class="badge primary" *ngIf="recordList.length">{{recordList.length}}</span></a>
        <ng-template ngbNavContent>
          <div class="container-fluid" style="padding-bottom: 15px;">
            <div class="div-striped col-xs-12">
              <div *ngFor="let item of recordList" class="padded">
                <p class="text-muted small">
                  Initié par <span class="username">{{item.author}}</span> le
                    <span class="date">{{item.created_at|amDateFormat:'L LT'}}</span>.
                </p>
                <span [innerHtml]="item.text"></span>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
