import { Component, Input, ApplicationRef, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiHedwigService } from '@core/apis/api-hedwig.service';
import { PrintLabelModalComponent } from '@core/components/print-label-button/print-label-modal.component';
import { IPrinterOption, PRINTER_OPTIONS } from '@core/constants';
import { LabelPreferencesService } from '@core/services/label-preferences.service';
import { IGenericApi, IPrintLabelLrRecuperation, IPrintLabelObject_new, IPrintStockLabel } from '@app/core/interfaces';
import { PromisesService } from '@app/core/services/promises.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';

interface ICurrentEqp {
  code: string;
  seq?: string;
}

@Component({
  selector: 'app-print-label-button',
  templateUrl: './print-label-button.component.html',
  styleUrls: ['./print-label-button.component.less']
})
export class PrintLabelButtonComponent implements OnInit {
  @Input() public detail: any;
  @Input() public isSmallBtn: boolean;
  @Input() public buildPrintData: Function;
  @Input() public disabled: boolean;
  @Input() public currentEqp: ICurrentEqp | string;
  @Input() public owner: string;
  @Input() public origin: string = 'equipment_labels';

  public readonly printerOptions: IPrinterOption[] = PRINTER_OPTIONS;
  public printing: boolean;
  public selectedPrinter: string;
  private api: IGenericApi;

  constructor(
    private readonly apiHedwigService: ApiHedwigService,
    private readonly toastr: ToastrService,
    private readonly ngbModal: NgbModal,
    private readonly appRef: ApplicationRef,
    public readonly labelPreferencesService: LabelPreferencesService,
    private apiShiva: ApiShivaService,
    private promisesService: PromisesService,
    private readonly signalsService: SignalsService,
  ) {
    // Api used for fetch, update and create
    this.api = this.apiShiva.logistics_requests as IGenericApi;
  }

  public ngOnInit(): void {
    this.selectedPrinter = this.labelPreferencesService.getInitialValue();
  }

  public async directPrint() {
    try {
      if (this.origin === 'equipment_labels') {
        const printData: IPrintLabelObject_new = await this.buildPrintData();
        if (!this.currentEqp) {
          // On direct and global print, the back define the labels data without the front
          delete printData.labels_data;
        }
        this._print(printData);
      }
      else if (this.origin === 'lr_recuperation' ) {
        const printDatalr: IPrintLabelLrRecuperation = await this.buildPrintData_lr();
        this._print(printDatalr);
      }
      else if (this.origin === 'sn_stock') {

        const today = new Date();
        const formattedDate = today.toLocaleDateString('fr-FR');
        const eqp_data = [{serial_number: this.currentEqp['serial_number'] || '', internal_sn: this.currentEqp['internal_serial_number'] || ''}];

        const printDataStock: IPrintStockLabel = {model: this.currentEqp['model']['name'],
                                                  owner:this.owner, entry_date: formattedDate,
                                                  equipments: eqp_data};

        this._print(printDataStock);
      }
    } catch (error) {
      console.error(`Erreur lors de la récupération des données d'impression.\n${error}`);
    }
  }

  public buildPrintData_lr(): Promise<IPrintLabelLrRecuperation> {
    const deferred = this.promisesService.defer();

    const lrCode = this.detail.code;
    const printData: IPrintLabelLrRecuperation = {
      lr_code: lrCode || '',
    };
    deferred.resolve(printData);

    return deferred.promise;
  }

  public async customPrint() {
    try {
      if (this.origin === 'equipment_labels') {
        const printData: IPrintLabelObject_new = await this.buildPrintData(this.currentEqp);
        const modal = this.ngbModal.open(PrintLabelModalComponent, {backdrop: 'static'});
        modal.componentInstance.labelObject = printData;
        this.appRef.tick();  // Angular bug? https://github.com/ng-bootstrap/ng-bootstrap/issues/3348
        this._print(await modal.result);
      }
      else if (this.origin === 'lr_recuperation') {
        const printData: IPrintLabelLrRecuperation = await this.buildPrintData_lr();
        const modal = this.ngbModal.open(PrintLabelModalComponent, {backdrop: 'static'});
        modal.componentInstance.labelObject = printData;
        this.appRef.tick();  // Angular bug? https://github.com/ng-bootstrap/ng-bootstrap/issues/3348
        this._print(await modal.result);
      }
    } catch (error) {
      console.error(`Erreur lors de la récupération des données d'impression.\n${error}`);
    }
  }

  private _print(printLabelObject: IPrintLabelObject_new | IPrintLabelLrRecuperation | IPrintStockLabel) {
    this.printing = true;
    if (this.origin === 'equipment_labels' && 'woi_code' in printLabelObject) {
      const payload = {...{printer: this.selectedPrinter}, ...printLabelObject};
      this.apiHedwigService.print_equipment_labels(payload)
      .then(() => this.toastr.info('Impression en cours...'))
      .catch(err => this.toastr.error(`Erreur lors de l'impression de l'étiquette: ${JSON.stringify(err.error)}`))
      .finally(() => this.printing = false);
    }
    // sn stock printing
    else if (this.origin === 'sn_stock' && 'model' in printLabelObject) {
      const payload = {...{printer: this.selectedPrinter}, ...printLabelObject};
      this.apiHedwigService.print_sn_stock(payload)
      .then(() => this.toastr.info('Impression en cours...'))
      .catch(err => this.toastr.error(`Erreur lors de l'impression de l'étiquette: ${JSON.stringify(err.error)}`))
      .finally(() => this.printing = false);
    }
    else if (this.origin === 'lr_recuperation' && 'lr_code' in printLabelObject) {
      const payload = {...{printer: this.selectedPrinter}, ...printLabelObject};
      this.apiHedwigService.print_logistics_request_recuperation_labels(payload)
      .then(() => this.toastr.info('Impression en cours...'))
      .catch(err => this.toastr.error(`Erreur lors de l'impression de l'étiquette: ${JSON.stringify(err.error)}`))
      .finally(() => this.printing = false);
    }
  }
}
