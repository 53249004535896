import { Component, OnInit, Injector, Input, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { getDiff } from 'recursive-diff';
import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { isObject } from '@core/helpers';
import { IModelHistory } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';


@Component({
  selector: 'app-model-histories-list',
  templateUrl: './model-histories-list.component.html',
  styleUrls: ['./model-histories-list.component.less'],
  providers: [DecimalPipe]
})
export class ModelHistoriesListComponent implements OnInit, OnDestroy {

  @Input() public model: string;
  @Input() public id: string;
  @Input() public isParent: Boolean;
  @Input() public createdBy: string; // optional parameter to display the creator of the model
  @Input() public createdAt: string; // optional parameter to display the creation date of the model

  public historic: IModelHistory[];
  public loading: boolean;
  private signalsSubscription: Subscription[] = [];
  private modelHistoryFields = {
    entity: {
      payment_method_affinity: 'Méthode de paiement',
      payment_term_affinity: 'Modalité de paiement',
      type: 'Type d\'entité',
      name: 'Nom',
      sync_salesforce: 'Sync Salesforce',
      ndi: 'NDI de référence'
    },
    order: {
      entity: 'Client',
      company: 'Société',
      exchange_rate: 'Taux de change',
      assignee: 'Pilote de commande',
      project: 'Projet',
      payment_method: 'Méthode de paiement',
      payment_term: 'Modalité de paiement',
      language_affinity: 'Langue de facturation',
      customer_ref: 'Référence client',
      internal_ref: 'Référence interne',
      provisional_end_date: 'Fin prévue le'
    },
    quote: {
      entity: 'Client',
      company: 'Société',
      customer_ref: 'Référence client',
      exchange_rate: 'Taux de change',
      payment_method: 'Méthode de paiement',
      payment_term: 'Échéance de paiement',
      language_affinity: 'Langue du devis',
      salesperson: 'Commercial'
    },
    currency: {
      conversion_rate: 'Taux'
    },
    product: {
      name: 'Nom',
      category: 'Catégorie',
      valorization_value: 'Valorisation',
      type: 'Type',
      product_parameter_option_label: 'Nom',
      product_parameter_option_value: 'Valeur',
      product_parameter_option_condition: 'Condition',
      product_parameter_option_default: 'Défaut',
      product_parameter_option_price_purchase_nrc_formula: 'Prix catalogue NRC',
      product_parameter_option_price_purchase_mrc_formula: 'Prix catalogue MRC',
      product_parameter_option_price_minimum_nrc_formula: 'Prix plancher NRC',
      product_parameter_option_price_minimum_mrc_formula: 'Prix plancher MRC',
      product_parameter_option_price_public_nrc_formula: 'Prix catalogue NRC',
      product_parameter_option_price_public_mrc_formula: 'Prix catalogue MRC',
      product_parameter_name: 'Nom',
      product_parameter_key: 'Attribut des métadonnées',
      product_parameter_kind: 'Type',
      product_parameter_condition: 'Condition',
      product_parameter_value_validation: 'Validation de la valeur'
    },
    workorderitem: {
      provisional_start_date: 'Début prévu le',
      provisional_end_date: 'Fin prévue le',
      due_date: 'Date souhaitée'
    },
    operatorline: {
      entity: 'Entité',
      technical_ref: 'Prestation',
      offer: 'Offre',
      collection_node: 'Porte de collecte',
      pairs: 'Paires',
      max_bps_up: 'Débit max montant',
      max_bps_down: 'Débit max descendant',
      max_bps_up_guaranteed: 'Débit montant garanti',
      max_bps_down_guaranteed: 'Débit descendant garanti',
      external_ref: 'Identification de facturation ',
      order_ref: 'Référence de commande',
      status: 'Statut'
    },
    configtemplate: {
      name: 'Nom',
      type: 'Type'
    },
    provider: {
      name: 'Nom',
      portal_url: 'Url du portail'
    },
    offer: {
      technology: 'Technologie',
      name: 'Nom',
      prefix_ppp: 'Préfixe login PPP',
      suffix_ppp: 'Suffixe login PPP',
      description: 'Description',
      delivery_days: 'Délai de livraison'
    },
    servervrf: {
      name: 'Nom',
      name_backbone: 'Nom backbone',
      number_rd: 'N° RD',
      proxy_client: 'Machine de rebond',
      is_active: 'Actif'
    },
    line: {
      quarantine: 'Niveau de quarantaine',
      usage: 'Utilisation',
      external_label: 'Label monitoring',
      qos: 'Classe QoS',
      created_by: 'Créé par',
      routing_kind: 'Routage',
      autonomous_system: 'BGP AS',
    },
    collectionnode: {
      port: 'Interface',
      type: 'Type',
      reference: 'Référence',
      equipments_backbone: 'Eqp. backbone',
      datacenter: 'Datacenter',
      monitoring_label: 'Label de monitoring',
      reporting_link: 'URL monitoring',
      reporting_image_link: 'URL image monitoring'
    },
    networkdevice: {
      equipment: 'Equipement',
      operator_line: 'Lien opérateur',
      config_template: 'Modèle de conf.',
      number: 'Numéro',
      first_sim_card_equipment: 'SIM 1',
      second_sim_card_equipment: 'SIM 2',
    },
    billofmaterials: {
      name: 'Nom',
      quantity_handling: 'Quantification',
      description: 'Description',
      product: 'Produit'
    },
    traceability: {
      cancellation_date: 'Résilié le',
      activation_date: 'Activé le',
      quantity: 'Quantité',
      unit_price_wd: 'Prix unitaire',
      periodicity: 'Périodicité',
      name: 'Nom',
      payable: 'Type de paiement',
      description: 'Description',
      customer_ref: 'Réf. Client',
      product_config: 'Configuration de produit'
    },
    providertraceability: {
      price_purchase_mrc: 'Prix d\'achat MRC',
      price_purchase_nrc: 'Prix d\'achat NRC',
      periodicity: 'Périodicité',
      commitment: 'Durée d\'engagement',
    },
    contact: {
      statut: 'Statut',
      first_name: 'Prénom',
      last_name: 'Nom',
      entity: 'Entité Parent',
      function: 'Fonction',
      email: 'Email',
      fax: 'Fax',
      phone: 'Téléphone',
      mobile: 'Mobile',
      default: 'Défaut',
      is_mydso: 'MyDSO'
    },
    logisticsrequest: {
      due_date: 'Date souhaitée',
      done_date: 'Date de fin',
      title: 'Titre',
      site: 'Site',
      shipping_location: 'Adresse d\'expédition',
      order: 'Commande',
      project: 'Projet',
      without_shipping: 'Sans expédition',
      urgent_shipping: 'Expédition urgente',
      description: 'Description',
    },
    project: {
      end_date: 'Fin de période',
      objective_project: 'Objectif project',
      objective_period: 'Objectif période',
      current: 'Valeur actuel',
    },
    carrieraccount: {
      carrier: 'Transporteur',
      account_id: 'Identifiant',
      account_password: 'Mot de passe',
      licence_number: 'Numéro de licence',
    },
    logisticssite: {
      number: 'Numéro',
      owner: 'Propriétaire',
    },
  };


  constructor(
    public injector: Injector,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private decimalPipe: DecimalPipe,
    private toastr: ToastrService,
    private userService: UserService,
  ) {
  }

  public ngOnInit(): void {
    this.fetch();
    this.signalsSubscription.push(
      this.signalsService.subscribe(
        'model-history-list-refresh',
        this.fetch.bind(this)
      )
    );
  }

  public ngOnDestroy(): void {
    this.signalsSubscription.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private fetch(): Promise<void> {
    this.loading = true;
    return this.apiShiva.models_history.list(this.model, this.id)
      .then(
        (res: IModelHistory[]) => {
          this.historic = this.formatRecords(res);
        },
        err => {
          console.error(`Erreur lors de la récupération de l'historique`, err);
          this.toastr.error(`Erreur lors de la récupération de l'historique`);
        }
      )
      .finally(() => this.loading = false);
  }

  /**
   * main entry point for building the text displaying each modification
   */
  private formatRecords(records: IModelHistory[]): IModelHistory[] {
    records.forEach((record) => {
      record.context = record.context ? record.context : {};
      let fieldName = this.modelHistoryFields[record.content_type_name] ? this.modelHistoryFields[record.content_type_name][record.fieldname] : record.fieldname;
      // in case the model had some fields defined, but not this one, provide a default value here.
      fieldName = fieldName || record.fieldname;

      switch (record.action) {
        case 'added':
          this.formatRecordsAdd(record, fieldName);
          break;
        case 'updated':
          this.formatRecordsUpdate(record, fieldName);
          break;
        case 'removed':
          this.formatRecordsRemove(record, fieldName);
          break;
        case 'created':
          this.formatRecordsCreate(record, fieldName);
          break;
        case 'destroyed':
          this.formatRecordsDestroy(record, fieldName);
          break;
        default:
          record.displayStr = `Action inconnue sur le champ <b>${fieldName}</b> ${this.changeValMsg(record.previous_value, record.current_value)}`;
          break;
      }
    });
    return records;
  }

  /**
   * formatting for when a value was added to a previously-empty field
   */
  private formatRecordsAdd(record: IModelHistory, fieldName: string): void {
    const objectUpdated = record.context.object_updated ? record.context.object_updated : null;
    const identifier = record.context.identifier ? record.context.identifier : null;

    switch (objectUpdated) {
      case 'Entity': {
        if (record.context.action === 'updated_payable') {
          record.displayStr = `Mise à jour du champ <b>${fieldName}</b> `;
          const isStandard = this.isParent ? 'Standard' : 'Hérité du parent';
          if (record.current_value === '0') {
            record.displayStr += `(${isStandard} -> <b>Terme à échoir</b>)`;
          } else {
            record.displayStr += `(${isStandard} -> <b>Terme échu </b>)`;
          }
        }
        break;
      }
      case 'ProviderTraceability':
        record.permissions = 'Antoine:ProviderTraceabilityFinanceCanDisplay';
        record.displayStr = record?.context?.action === 'created'
          ? `Création`
          : `Ajout du champ <b>${fieldName}</b> (nouvelle valeur : <b>${record.current_value}</b>)`;
        break;
      default:
        record.displayStr = record?.context?.action === 'created'
          ? `Création`
          : `Ajout du champ <b>${fieldName}</b> (nouvelle valeur : <b>${record.current_value}</b>)`;
        if (objectUpdated) {
          record.displayStr += `, sur objet: <b>${objectUpdated}</b>`;
          if (identifier) {
            record.displayStr += ` (${identifier})`;
          }
        }
        break;
    }
  }

  /**
   * formatting for when a value was removed from a field
   */
  private formatRecordsRemove(record: IModelHistory, fieldName: string): void {
    const objectUpdated = record.context.object_updated ? record.context.object_updated : null;
    const identifier = record.context.identifier ? record.context.identifier : null;
    switch (objectUpdated) {
      case 'Entity': {
        if (record.context.action === 'updated_payable') {
          record.displayStr = `Mise à jour du champ <b>${fieldName}</b> `;
          const isStandard = this.isParent ? 'Standard' : 'Hérité du parent';
          if (record.previous_value === '0') {
            record.displayStr += `(Terme à échoir -> <b>${isStandard}</b>)`;
          } else {
            record.displayStr += `(Terme échu -> <b>${isStandard}</b>)`;
          }
        }
        break;
      }

      case 'Traceability':
        if (fieldName === 'relation_with_order_item') {
          record.displayStr = `Suppression de la relation avec la commande <b>${record.previous_value}</b> suite à une remodification de service`;
        }
        if (fieldName === 'relation_without_order_item') {
          record.displayStr = `Suppression de la relation d'une modification manuelle suite à une remodification de service`;
        }
        break;

      case 'ProviderTraceability':
        record.permissions = 'Antoine:ProviderTraceabilityFinanceCanDisplay';
        record.displayStr = `Suppression du champ <b>${fieldName}</b> (ancienne valeur : <b>${record.previous_value}</b>)`;
        break;

      default:
        record.displayStr = `Suppression du champ <b>${fieldName}</b> (ancienne valeur : <b>${record.previous_value}</b>)`;
        if (objectUpdated) {
          record.displayStr += `, sur objet: <b>${objectUpdated}</b>`;
          if (identifier) {
            record.displayStr += ` (${identifier})`;
          }
        }
        break;
    }
  }

  /*
  * formatting the message used when an object is deleted and displayed on an associated object
  */
  private formatRecordsDestroy(record: IModelHistory, fieldName: string): void {

    const objectUpdated = record.context.object_updated ? record.context.object_updated : null;
    const noMore = record.context.no_more ? record.context.no_more : null; //param to display short message

    switch (objectUpdated) {
      case 'ArgosEventMute': {
        let dateFrom = '';
        let dateTo = '';
        if (record.context.date_to) {
          dateFrom = moment(record.context.date_from).format('L LT');
          dateTo = moment(record.context.date_to).format('L LT');
        }
        record.displayStr = `Suppression de fenêtre de maintenance: (<b>${dateFrom}</b> - <b>${dateTo}</b>)`;
        break;
      }

      case 'VRFLanSubnetRoute': {
        const detail = record.context ? record.context.detail : null;
        record.displayStr = `Suppression de route: (<b>${detail}</b>)`;
        break;
      }

      case 'FiscalPosition': {
        const detail = record.context ? record.context.detail : null;
        record.displayStr = `Suppression de FiscalPosition: (<b>${detail}</b>)`;
        break;
      }

      case 'ProjectIndicator': {
        const name = record.context ? record.context.name : '';
        record.displayStr = `Suppression de project indicateur: (<b>${name}</b>)`;
        break;
      }

      case 'ProviderTraceability':
        record.permissions = 'Antoine:ProviderTraceabilityFinanceCanDisplay';
        record.displayStr = `Suppression de <b>${objectUpdated}</b> (${record.fieldname} : <b>${record.current_value}</b>).`;
        break;

      default:
        if (noMore !== null) {
          record.displayStr = `Suppression de <b>${objectUpdated}</b>.`;
        }
        record.displayStr = `Suppression de <b>${objectUpdated}</b> (${record.fieldname} : <b>${record.current_value}</b>).`;
        break;
    }
  }

  /*
  * formatting the message used when an object is created and displayed on an associated object
  */
  private formatRecordsCreate(record: IModelHistory, fieldName: string): void {

    const objectUpdated = record.context.object_updated ? record.context.object_updated : null;
    const noMore = record.context.no_more ? record.context.no_more : null; //param to display short message

    switch (objectUpdated) {
      case 'ArgosEventMute': {
        let dateFrom = '';
        let dateTo = '';
        if (record.context.date_to) {
          dateFrom = moment(record.context.date_from).format('L LT');
          dateTo = moment(record.context.date_to).format('L LT');
        }
        record.displayStr = `Création de fenêtre de maintenance: (<b>${dateFrom}</b> - <b>${dateTo}</b>)`;
        break;
      }
      case 'VRFLanSubnetRoute': {
        const detail = record.context ? record.context.detail : null;
        record.displayStr = `Création de route: (<b>${detail}</b>)`;
        break;
      }
      case 'FiscalPosition': {
        const detail = record.context ? record.context.detail : null;
        record.displayStr = `Création de FiscalPosition: (<b>${detail}</b>)`;
        break;
      }
      case 'ProjectIndicator': {
        const name = record.context ? record.context.name : '';
        record.displayStr = `Création de project indicateur: (<b>${name}</b>)`;
        break;
      }

      case 'ProviderTraceability':
        record.permissions = 'Antoine:ProviderTraceabilityFinanceCanDisplay';
        record.displayStr = `Ajout de <b>${objectUpdated}</b> (${record.fieldname} : <b>${record.current_value}</b>).`;
        break;

      default:
        if (noMore !== null) {
          record.displayStr = `Ajout de <b>${objectUpdated}</b>.`;
        }
        record.displayStr = `Ajout de <b>${objectUpdated}</b> (${record.fieldname} : <b>${record.current_value}</b>).`;
        break;
    }
  }

  /*
  * formatting the message used when the value of a field changes from one non-null value to another
  */
  private formatRecordsUpdate(record: IModelHistory, fieldName: string): void {
    const action = record.context ? record.context.action : null;
    const objectUpdated = record.context.object_updated ? record.context.object_updated : null;
    const identifier = record.context.identifier ? record.context.identifier : null;

    switch (action) {
      // We keep these 4 ones for backward compatibility with the old records
      case 'disable_site':
        record.displayStr = `Désactivation de l'entité`;
        break;
      case 'enable_site':
        record.displayStr = `Réactivation de l'entité`;
        break;
      case 'close_site':
        record.displayStr = `Fermeture de l'entité`;
        break;
      case 'open_site':
        record.displayStr = `Réouverture de l'entité`;
        break;
      // these two are the new records handler for the open/close & disable site actions
      case 'is_site_open':
        if (record.current_value === 'True') {
          record.displayStr = `Réouverture de l'entité`;
        } else {
          record.displayStr = `Fermeture de l'entité`;
        }
        break;
      case 'is_entity_active':
        if (record.current_value === 'True') {
          record.displayStr = `Réactivation de l'entité`;
        } else {
          record.displayStr = `Désactivation de l'entité`;
        }
        break;
      case 'merge_entity_from':
        record.displayStr = `Désactivation de l'entité après fusion dans l'entité <a href="/#/entities/detail/${record.current_value}">${record.current_value}</a>.`;
        break;
      case 'merge_entity_to':
        record.displayStr = `Entité fusionnée avec l'entité <a href="/#/entities/detail/${record.previous_value}">${record.previous_value}</a>.`;
        break;
      case 'update_order_item_quantity':
        record.displayStr = record.context.group_name ? `${record.context.group_name} - ` : '';
        record.displayStr += `<b>${record.context.product_name}</b><br>Modification de la quantité ${this.changeValMsg(record.previous_value, record.current_value, true)}.<br>Motif : ${record.context.qty_update_reason}`;
        break;
      case 'update_order_item_setup_unit_price':
        record.displayStr = record.context.group_name ? `${record.context.group_name} - ` : '';
        record.displayStr += `<b>${record.context.product_name}</b><br>Modification des frais d'activation ${this.changeValMsg(record.previous_value, record.current_value, true)}.`;
        break;
      case 'update_order_item_recurring_unit_price':
        record.displayStr = record.context.group_name ? `${record.context.group_name} - ` : '';
        record.displayStr += `<b>${record.context.product_name}</b><br>Modification des frais récurrents ${this.changeValMsg(record.previous_value, record.current_value, true)}.̀`;
        break;
      case 'product_configurations_import':
        if (record.previous_value) {
          const attachmentLink = this.apiShiva.attachments.downloadUrl(record.previous_value);
          record.displayStr = `Import de nouvelles configurations de produit (<a href="${attachmentLink}">Ancien fichier</a>)`;
        } else {
          record.displayStr = 'Import de configurations de produit';
        }
        break;
      case 'product_parameters_import':
        if (record.previous_value) {
          const attachmentLink = this.apiShiva.attachments.downloadUrl(record.previous_value);
          record.displayStr = `Import de nouveaux paramètres de vente (<a href="${attachmentLink}">Ancien fichier</a>)`;
        } else {
          record.displayStr = `Import de paramètres de vente`;
        }
        break;
      case 'updated_sales_parameter': {
        let displayStr = `Mise à jour du champ <b>${fieldName}</b> ${this.changeValMsg(record.previous_value, record.current_value)} pour le paramètre de vente <b>${record.context.product_parameter_name}</b>`;
        if (record.context.type === 'KIND_MULTIPLE') {
          displayStr += ` pour l'option <b>${record.context.parameter_option_name}</b>`;
        }
        record.displayStr = displayStr;
        break;
      }
      case 'deleted_sale_parameter_option':
        if (record.context.deleted_option_name === 'unused_label') {
          record.hide = true;
        } else {
          record.displayStr = `Suppression de l'option <b>${record.context.deleted_option_name}</b> du paramètre de vente <b>${record.context.product_parameter_name}</b>`;
        }
        break;
      case 'created_sale_parameter_option':
        if (record.context.product_parameter_option_name === 'unused_label') {
          record.hide = true;
        } else {
          record.displayStr = `Ajout de l'option <b>${record.context.product_parameter_option_name}</b> au paramètre de vente <b>${record.context.product_parameter_name}</b>`;
        }
        break;
      case 'created_product_parameter':
        record.displayStr = `Ajout du paramètre de vente <b>${record.context.product_parameter_name}</b>`;
        break;
      case 'deleted_product_parameter':
        record.displayStr = `Suppression du paramètre de vente <b>${record.context.deleted_product_parameter_name}</b>`;
        break;
      case 'updated_product_parameter':
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> ${this.changeValMsg(record.previous_value, record.current_value)} pour le paramètre de vente <b>${record.context.product_parameter_name}</b>`;
        break;
      case 'pricebook_item_update':
        record.displayStr = `Modification de prix de la configuration de vente <b>${record.context.label}</b> pour le produit <b>${record.context.product_name}</b>`;
        break;
      case 'delete_pricebook_product':
        record.displayStr = `Suppression du produit <b>${record.context.product_name}</b> des conditions de vente`;
        break;
      case 'add_pricebook_item':
        record.displayStr = `Ajout de la configuration <b>${record.context.label}</b> pour le produit <b>${record.context.product_name}</b>`;
        break;
      case 'delete_pricebook_item':
        record.displayStr = `Suppression de la configuration <b>${record.context.label}</b> pour le produit <b>${record.context.product_name}</b>`;
        break;
      case 'delete_equipment_item':
        record.displayStr = `Suppression de l'équipement <b>${record.context.label}</b> de la demande logistique <b>${record.context.lr_code}</b>`;
        break;
      case 'metadata_woi':
        record.displayStr = this.formatJSONDiff(record.previous_value, record.current_value, 'metadonnés');
        break;
      case 'ssids_template':
        record.displayStr = this.formatJSONDiff(record.previous_value, record.current_value, 'ssids');
        break;
      case 'product_config_woi':
        record.displayStr = this.formatJSONDiff(record.previous_value, record.current_value, 'configuration produit');
        break;
      case 'bom_item_add':
        record.displayStr = `Ajout d'une règle pour le produit <b>${record.context.product_code}</b> (${record.context.product_name})`;
        break;
      case 'bom_item_remove':
        record.displayStr = `Suppression d'une règle pour le produit <b>${record.context.product_code}</b> (${record.context.product_name})`;
        break;
      case 'bom_item_quantity':
        record.displayStr = `Changement de <b>Quantité</b> d'une règle pour le produit <b>${record.context.product_code}</b> ${this.changeValMsg(record.previous_value, record.current_value, true)}`;
        break;
      case 'bom_item_processing':
        record.displayStr = `Changement de <b>Processing</b> d'une règle pour le produit <b>${record.context.product_code}</b> ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      case 'updated_payable':
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> `;
        if (record.previous_value === '0') {
          record.displayStr += `(Terme à échoir -> <b>Terme échu</b>)`;
        } else {
          record.displayStr += `(Terme échu -> <b>Terme à échoir</b>)`;
        }
        break;
      case 'contact_entity_relationship_create':
        record.displayStr = `Création de relation contact: <b>${record.context.first_name} ${record.context.last_name} (${record.context.contact_code})</b>`;
        break;
      case 'contact_entity_relationship_delete':
        record.displayStr = `Suppression de relation contact: <b>${record.context.first_name} ${record.context.last_name} (${record.context.contact_code})</b>`;
        break;
      case 'line_password_update':
        record.displayStr = `Mot de passe modifié.`;
        break;
      case 'carrier_account_password_update':
        record.displayStr = `Mot de passe du compte transporteur modifié.`;
        break;
      case 'carrier_client_secret_update':
        record.displayStr = `Client secret du compte transporteur modifié.`;
        break;
      case 'carrier_client_id_update':
        record.displayStr = `ID client du compte transporteur modifié.`;
        break;
      case 'add_zipcode':
        record.displayStr = `Ajout du code postal : <b>${record.context.zipcode}, ${record.context.city}</b>`;
        break;
      case 'import_zipcode':
        record.displayStr = `Ajout (import csv) du code postal : <b>${record.context.zipcode}, ${record.context.city}</b>`;
        break;
      case 'remove_zipcode':
        record.displayStr = `Suppression du code postal : ${record.context.zipcode}, ${record.context.city}`;
        break;
      case 'add_portal_account':
        record.displayStr = `Ajout du compte portail : <b>${record.context.email}</b>`;
        break;
      case 'remove_portal_account':
        record.displayStr = `Suppression du compte portail : <b>${record.context.email}</b>`;
        break;

      case 'm2m_update':
        record.displayStr = `Mise à jour du champ <b>${record.fieldname}</b> `;
        if (record.context.operation === 'added') {
          record.displayStr += `(Ajout de ${record.fieldname}: <b>${record.current_value}</b>)`;
        } else if (record.context.operation === 'removed') {
          record.displayStr += `(Suppression de ${record.fieldname}: <b>${record.current_value}</b>)`;
        }
        break;

      case 'translation':
        if (record.context.operation === 'added') {
          record.displayStr = 'Ajout de traduction pour les champs: ';
          record.displayStr += `<b>${record.context.field} (${record.context.iso_code}): ${record.current_value}</b>`;
        } else if (record.context.operation === 'removed') {
          record.displayStr = 'Suppression de traduction pour les champs: ';
          record.displayStr += `<b>${record.context.field} (${record.context.iso_code}): ${record.previous_value}</b>`;
        }
        break;

      case 'argos_event_mute': {
        let dateFrom = '';
        let dateTo = '';
        if (record.context.date_to) {
          dateFrom = moment(record.context.date_from).format('L LT');
          dateTo = moment(record.context.date_to).format('L LT');
        }
        record.displayStr = `Mise a jour de fenêtre de maintenance (<b>${dateFrom}</b> - <b>${dateTo}</b>)
                            champ <b>${fieldName}</b>:
                            ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      }

      case 'update_entity_alert':
        fieldName = record.fieldname;
        record.displayStr = `Mise à jour d'un champ de l'Alerte <b>${fieldName}</b> (${record.previous_value} -> <b>${record.current_value}</b>)`;
        break;

      case 'zabbix_group_remove':
        record.displayStr = `Suppression d'un groupe zabbix: <b>${record.current_value}</b>`;
        break;

      case 'zabbix_group_add':
        record.displayStr = `Ajout d'un groupe zabbix: <b>${record.current_value}</b>`;
        break;

      case 'update_vrf_lan_subnet_route': {
        const detail = record.context ? record.context.detail : null;
        record.displayStr = `Mise à jour du <b>route backbone</b> (${detail}) <b>${fieldName}</b>: ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      }

      case 'add_entity_to_vrf': {
        record.displayStr = `Ajoute d'entité <b>${record.current_value}</b>`;
        break;
      }

      case 'remove_entity_from_vrf': {
        record.displayStr = `Suppression d'entité <b>${record.current_value}</b>`;
        break;
      }

      case 'update_project_item': {
        const name = record.context ? record.context.name : '';
        record.displayStr = `Mise à jour du <b>indicateur</b> (${name}) <b>${fieldName}</b>: ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      }

      case 'location_update': {
        const fieldMap = {
          address: 'Adresse',
          zipcode: 'Code postal',
          city: 'Ville',
          country: 'Pays',
          latitude: 'Latitude',
          longitude: 'Longitude',
          phone: 'Téléphone fixe',
          mobile: 'Mobile',
          email: 'adresse de messagerie électronique',
          company_name: 'raison sociale'
        };
        const locTypeMap = {
          default: 'principale',
          billing: 'de facturation',
          shipping: 'de livraison'
        };
        record.displayStr = `
          Mise à jour du champ <b>${fieldMap[record.fieldname]}</b> de l'adresse <b>${locTypeMap[record.context.location_type]}
          <a href="/#/locations/detail/${record.context.location_code}" target="_blank">(${record.context.location_code})</a></b>:
          ${this.changeValMsg(record.previous_value, record.current_value)}
        `;
        break;
      }

      case 'update_module_template': {
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> sur le module ${record.context.module} ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      }

      case 'update_external_ref': {
        record.previous_value = record.previous_value ? record.previous_value : 'Vide';
        record.current_value = record.current_value ? record.current_value : 'Vide';
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> sur le Network <b>${record.context.network}</b> ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;
      }

      case 'update_provider_traceability_finance':
        record.permissions = 'Antoine:ProviderTraceabilityFinanceCanDisplay';
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> ${this.changeValMsg(record.previous_value, record.current_value)}`;
        break;

      default: {
        record.displayStr = `Mise à jour du champ <b>${fieldName}</b> ${this.changeValMsg(record.previous_value, record.current_value)}`;
        if (objectUpdated) {
          record.displayStr += `, sur objet: <b>${objectUpdated}</b>`;
          if (identifier) {
            record.displayStr += ` (${identifier})`;
          }
        }
        break;
      }
    }
  }

  private changeValMsg(prevValue: string, curValue: string, formatValue?: boolean): string {
    if (formatValue) {
      prevValue = this.decimalPipe.transform(prevValue, '1.0-4');
      curValue = this.decimalPipe.transform(curValue, '1.0-4');
    }
    return `(${prevValue} -> <b>${curValue}</b>)`;
  }

  private formatJSONDiff(prevStr: string, currentStr: string, fieldLabel: string): string {
    let prev;
    let current;
    let diffStr;
    let prevValue;

    try {
      prev = JSON.parse(prevStr);
      current = JSON.parse(currentStr);
    } catch (e) {
      return `Erreur de parsing du JSON de l'historique de mise à jour du champ JSON.`;
    }

    const diffArray = getDiff(prev, current, true);
    let displayStr = `Mise à jour du champ ${fieldLabel} :<br><ul>`;
    diffArray.forEach((diffObj) => {
      let path = '';
      if (diffObj.path) {
        path = diffObj.path.join('.');
      }
      const currentValue = isObject(diffObj.val) ? JSON.stringify(diffObj.val) : diffObj.val;
      switch (diffObj.op) {
        case 'update':
          prevValue = isObject(diffObj.oldVal) ? JSON.stringify(diffObj.oldVal) : diffObj.oldVal;
          if (fieldLabel === 'ssids' && path) {
            diffStr = `Mise à jour du champ <b>${diffObj.path[1]}</b> pour le ssid numéro <b>${Number(diffObj.path[0]) + 1}</b> (${prevValue} -> <b>${currentValue}</b>)`;
            break;
          }
          else if (fieldLabel === 'ssids') {
            diffStr = `Première récupération des ssids`;
            break;
          }
          else {
            diffStr = `Mise à jour du champ <b>${path}</b> (${prevValue} -> <b>${currentValue}</b>)`;
            break;
          }
        case 'add':
          diffStr = `Ajout du champ <b>${path}</b> (nouvelle valeur : <b>${currentValue}</b>)`;
          break;
        case 'delete':
          prevValue = isObject(diffObj.oldVal) ? JSON.stringify(diffObj.oldVal) : diffObj.oldVal;
          diffStr = `Suppression du champ <b>${path}</b> (ancienne valeur : ${prevValue})`;
          break;
        default:
          diffStr = JSON.stringify(diffObj);
          break;
      }
      displayStr += `<li>${diffStr}</li>`;
    });

    displayStr += '</ul>';

    return displayStr;
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }
}
